export const GET_ARTICLES = "Containers/ListOfArticles/GET_ARTICLES"
export const GET_ARTICLES_SUCCESS =
  "Containers/ListOfArticles/GET_ARTICLES_SUCCESS"
export const GET_ARTICLES_FAILURE =
  "Containers/ListOfArticles/GET_ARTICLES_SUCCESS"

export const PUBLISH_ARTICLES = "Containers/ListOfArticles/PUBLISH_ARTICLES"
export const PUBLISH_ARTICLES_SUCCESS =
  "Containers/ListOfArticles/PUBLISH_ARTICLES_SUCCESS"
export const PUBLISH_ARTICLES_FAILURE =
  "Containers/ListOfArticles/PUBLISH_ARTICLES_SUCCESS"

export const UPDATE_ARTICLES = "Containers/ListOfArticles/UPDATE_ARTICLES"
export const UPDATE_ARTICLES_SUCCESS =
  "Containers/ListOfArticles/UPDATE_ARTICLES_SUCCESS"
export const UPDATE_ARTICLES_FAILURE =
  "Containers/ListOfArticles/UPDATE_ARTICLES_SUCCESS"

export const DELETE_ARTICLES = "Containers/ListOfArticles/DELETE_ARTICLES"
export const DELETE_ARTICLES_SUCCESS =
  "Containers/ListOfArticles/DELETE_ARTICLES_SUCCESS"
export const DELETE_ARTICLES_FAILURE =
  "Containers/ListOfArticles/DELETE_ARTICLES_SUCCESS"

export const FILTER_ARTICLES = "FILTER_ARTICLES"
export const FILTER_ARTICLES_SUCCESS = "FILTER_ARTICLES_SUCCESS"
export const FILTER_ARTICLES_FALUIRE = "FILTER_ARTICLES_FALUIRE"

export const SEARCH_ARTICLES = "SEARCH_ARTICLES"
export const SEARCH_ARTICLES_SUCCESS = "SEARCH_ARTICLES_SUCCESS"
export const SEARCH_ARTICLES_FALUIRE = "SEARCH_ARTICLES_FALUIRE"

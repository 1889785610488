import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { constants } from "./redux"
import { Card, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap"
import ArticleDetail from "../ArticleDetail"

import "./style.scss"
import moment from "moment"

import Images from "utils/Images"
import "react-datepicker/dist/react-datepicker.css"
import FilterComponent from "WebVisual/Components/FilterComponent"
import Pagination from "components/Pagination/Pagination"
import ActivityIndicator from "components/ActivityIndicator"

const FavouriteArticles = ({ search }) => {
  const [active, setActive] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const dispatch = useDispatch()
  const state = useSelector(state => state.VisualFavouritesReducer)
  const [offset, setOffset] = useState(0)
  const [selectPageValue, setSelectPageValue] = useState(1)

  useEffect(() => {
    dispatch({
      type: constants.GET_FAVOURITES,
      offset: offset
    })
  }, [])

  useEffect(() => {
    if (search) {
      dispatch({
        type: constants.DATE_FILETER,
        params: `name=${search}`
      })
    } else {
      setOffset(0);
      dispatch({
        type: constants.GET_FAVOURITES,
        offset: 0
      })
    }
  }, [search])

  const addFavourite = id => {
    dispatch({
      type: constants.ADD_FAVOURITE,
      id,
      offset
    })
  }

  const [isToggle, setIsToggle] = useState(false)
  const [isDateToggle, setIsDateToggle] = useState(false)
  const [date, setDate] = useState("")
  const [category, seCategory] = useState("")

  const onApplyFilter = (type, params) => {
    let dateQuery = date
    let categoryQuery = category
    if (type === "date") {
      setDate(params)
      dateQuery = params
    } else {
      seCategory(params)
      categoryQuery = params
    }
    if (dateQuery || categoryQuery) {
      dispatch({
        type: constants.DATE_FILETER,
        params: dateQuery + categoryQuery
      })
    } else {
      setOffset(0)
      dispatch({
        type: constants.GET_FAVOURITES,
        offset: 0
      })
    }
    setIsToggle(false)
    setIsDateToggle(false)
  }

  return (
    <div className="favArticleWrapper">
      {active ? (
        <ArticleDetail item={selectedItem} setActive={setActive} />
      ) : (
        <>
          <FilterComponent
            onApplyFilter={onApplyFilter}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            isDateToggle={isDateToggle}
            setIsDateToggle={setIsDateToggle}
            listFound={`${state.count || state.favourites.length || 0} Favourite article found`}
          />
          <Row className="article-wrapper">
            {state.requesting ? (
              <ActivityIndicator requesting />
            ) : state.requesting === false && state.favourites.length < 1 ? (
              <Row className="d-flex justify-content-center align-items-center m-auto">
                <p className="">No content found.</p>
              </Row>
            ) : (
              state.favourites.map(item => (
                <Col sm="6" md="4" xl="3" className="mb-5">
                  <Card body className="p-0">
                    <CardHeader className="cardHeader">
                      <img src={Images.cardLogo} />
                    </CardHeader>
                    <CardTitle
                      className="cardTitle"
                      onClick={() => {
                        setSelectedItem(item)
                        setActive(true)
                      }}
                    >
                      <p>{item.title}</p>
                    </CardTitle>
                    <CardText>
                      <Row className="cardData">
                        <p className="categoryText">Category:</p>
                        <p className="categoryType">{item.category}</p>
                      </Row>
                      <p className="categoryDetail">{item.short_summary}</p>
                      <div className="d-flex justify-content-between dateWrraper">
                        <p className="p-0">
                          {moment(item.date).format("MMMM, DD, YYYY")}
                        </p>
                        <img
                          onClick={() =>
                            addFavourite(item.id, !item.is_favorite)
                          }
                          src={
                            item.is_favorite ? Images.favIcon : Images.likeIcon
                          }
                          alt=""
                        />
                      </div>
                    </CardText>
                  </Card>
                </Col>
              ))
            )}
          </Row>
          {state.count > 10 && (
            <Row className="align-items-center justify-content-center">
            {state.requesting?"":  <Pagination
                totalCount={state && state.count}
                offset={offset}
                setOffset={setOffset}
                selectPageValue={selectPageValue}
                setSelectPageValue={setSelectPageValue}
              />}
            </Row>
          )}
        </>
      )}
    </div>
  )
}

export default FavouriteArticles

import React, { useState, useEffect } from "react"
import { Switch, Router, Redirect } from "react-router-dom"
import AuthLayout from "layouts/Auth.js"
import AdminLayout from "layouts/Admin.js"
import UserLayout from "layouts/User"
import RouteGuard from "./RouterGuard"
import { store, history, persistor } from "./Redux/store"
import { getFirebaseToken } from "./firebase"
import { onForegroundMessage } from "./firebase"
import { useDispatch } from "react-redux"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { constants } from "WebVisual/Components/Notifications/redux"

const App = () => {
  const accessToken = sessionStorage.getItem("prc_authToken")

  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  )

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  let initialState;
  if (!isIOS) {
    try {
      initialState = Notification.permission === "default";
    } catch (error) {
      console.error(error);
      initialState = false;
    }
  } else {
    initialState = false;
  }

  const [showNotificationBanner, setShowNotificationBanner] = useState(
    initialState
  )

  const dispatch = useDispatch()

  const handleGetFirebaseToken = () => {
    try {      
      getFirebaseToken()
        .then(firebaseToken => {
          // console.log("Firebase token: ", firebaseToken)
          if (firebaseToken) {
            const payload = {
              // device_id: "string",
              registration_id: firebaseToken,
              cloud_message_type: "FCM"
            }
            dispatch({
              type: constants.REGISTER_DEVICE,
              payload
            })
            setShowNotificationBanner(false)
          }
        })
        .catch(err =>
          console.log("An error occured while retrieving firebase token. ", err)
        )
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    onForegroundMessage()
      .then(payload => {
        // console.log("Received foreground message: ", payload)
        const {
          notification: { title, body }
        } = payload
        toast(<ToastifyNotification title={title} body={body} />)
      })
      .catch(err =>
        console.log(
          "An error occured while retrieving foreground message. ",
          err
        )
      )
    accessToken &&
      history.location.pathname.split("/", 3)[1] === "user" &&
      handleGetFirebaseToken()
  }, [accessToken])

  return (
    <Router history={history}>
      <Switch>
        <RouteGuard
          path="/auth"
          component={props => <AuthLayout {...props} />}
        />
        <RouteGuard
          path="/admin"
          component={props => <AdminLayout {...props} />}
          isProtected
        />
        <RouteGuard
          path="/user"
          component={props => <UserLayout {...props} />}
          isProtected
        />
        {accessToken ? <Redirect to="/user" /> : <Redirect to="/auth/login" />}
      </Switch>
      <ToastContainer hideProgressBar />
      {showNotificationBanner && (
        <div className="notification-banner">
          <span>The app needs permission to</span>
          <a
            href="#"
            className="notification-banner-link"
            onClick={handleGetFirebaseToken}
          >
            enable push notifications.
          </a>
        </div>
      )}
    </Router>
  )
}

export default App

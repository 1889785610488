import {
  // ADD_MODAL,
  // EDIT_MODAL,
  GET_ALL_CLIENT_CALLS,
  GET_ALL_CLIENT_CALLS_SUCCESS,
  GET_ALL_CLIENT_CALLS_FALUIRE,
  CREATE_CLIENT_CALL,
  CREATE_CLIENT_CALL_SUCCESS,
  CREATE_CLIENT_CALL_FALUIRE,
  PAGINATION_HANDLING,
  UPDATE_CLIENT_CALL,
  UPDATE_CLIENT_CALL_SUCCESS,
  UPDATE_CLIENT_CALL_FALUIRE,
  DELETE_CLIENT_CALL,
  DELETE_CLIENT_CALL_SUCCESS,
  DELETE_CLIENT_CALL_FALUIRE,
  FILTER_CLIENT_CALL,
  FILTER_CLIENT_CALL_SUCCESS,
  FILTER_CLIENT_CALL_FALUIRE,
  SEARCH_CLIENT_CALL,
  SEARCH_CLIENT_CALL_SUCCESS,
  SEARCH_CLIENT_CALL_FALUIRE,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE
} from "./types"

const initialState = {
  call: false,
  error: false,
  pageValue: 11,
  requesting: false,
  updatedCall: false,
  deletedCall: false,
  clientCalls: false,
  callRequesting: false,
  updateRequesting: false,
  deleteRequesting: false,
  editModal: false,
  addModal: false,
  filteredResults: false,
  filterRequesting: false,
  searchedResults: false,
  searchRequesting: false,
  categories: false,
  categoryRequesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    // case ADD_MODAL:
    //   return {
    //     ...state,
    //     addModal: action.data
    //   }

    // case EDIT_MODAL:
    //   return {
    //     ...state,
    //     editModal: action.data
    //   }

    case GET_ALL_CLIENT_CALLS:
      return {
        ...state,
        requesting: true
      }

    case GET_ALL_CLIENT_CALLS_SUCCESS:
      return {
        ...state,
        requesting: false,
        clientCalls: action.data
      }

    case GET_ALL_CLIENT_CALLS_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.error
      }

    case CREATE_CLIENT_CALL:
      return {
        ...state,
        callRequesting: true
      }

    case CREATE_CLIENT_CALL_SUCCESS:
      return {
        ...state,
        callRequesting: false,
        call: action.data
      }

    case CREATE_CLIENT_CALL_FALUIRE:
      return {
        ...state,
        callRequesting: false,
        error: action.error
      }

    case UPDATE_CLIENT_CALL:
      return {
        ...state,
        updateRequesting: true
      }

    case UPDATE_CLIENT_CALL_SUCCESS:
      return {
        ...state,
        updateRequesting: false,
        updatedCall: action.data
      }

    case UPDATE_CLIENT_CALL_FALUIRE:
      return {
        ...state,
        updateRequesting: false,
        error: action.error
      }

    case DELETE_CLIENT_CALL:
      return {
        ...state,
        deleteRequesting: true
      }

    case DELETE_CLIENT_CALL_SUCCESS:
      return {
        ...state,
        deleteRequesting: false,
        deletedCall: action.data
      }

    case DELETE_CLIENT_CALL_FALUIRE:
      return {
        ...state,
        deleteRequesting: false,
        error: action.error
      }

    case FILTER_CLIENT_CALL:
      return {
        ...state,
        filterRequesting: true
      }

    case FILTER_CLIENT_CALL_SUCCESS:
      return {
        ...state,
        filterRequesting: false,
        filteredResults: action.data
      }

    case FILTER_CLIENT_CALL_FALUIRE:
      return {
        ...state,
        filterRequesting: false,
        filteredResults: false
      }

    case SEARCH_CLIENT_CALL:
      return {
        ...state,
        searchRequesting: true
      }

    case SEARCH_CLIENT_CALL_SUCCESS:
      return {
        ...state,
        searchRequesting: false,
        searchedResults: action.data
      }

    case SEARCH_CLIENT_CALL_FALUIRE:
      return {
        ...state,
        searchRequesting: false,
        searchedResults: false
      }

    case GET_CATEGORY:
      return {
        ...state,
        categoryRequesting: true
      }

    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryRequesting: false,
        categories: action.data
      }

    case GET_CATEGORY_FAILURE:
      return {
        ...state,
        categoryRequesting: false,
        error: action.error
      }

    case PAGINATION_HANDLING:
      return {
        ...state,
        pageValue: action.data
      }

    default:
      return state
  }
}

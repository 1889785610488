import {
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FALUIRE
} from "./types"

export const changeEmailRequest = data => ({
  type: CHANGE_EMAIL_REQUEST,
  data
})

export const changeEmailSuccess = data => ({
  type: CHANGE_EMAIL_SUCCESS,
  data
})

export const changeEmailFaluire = data => ({
  type: CHANGE_EMAIL_FALUIRE,
  data
})

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap"
import { constants } from '../redux'

import "../style.scss"

const interest = [
  { title: "Commodities", key: "is_commodities" },
  { title: "Equities", key: "is_equities" },
  { title: "FX", key: "is_fX" },
  { title: "Policy", key: "is_policy" },
  { title: "Politics", key: "is_politics" },
  { title: "Presentations", key: "is_presentations" },
  { title: "Property", key: "is_property" },
  { title: "Rates", key: "is_rates" }
]

const ProfileInformation = () => {
  const dispatch = useDispatch()
  const [isEditable, setIsEditable] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const user = useSelector(state => state.SignIn.user)
  const requesting = useSelector(state => state.VisualSettingsReducer.requesting)

  useEffect(() => {
    setUserProfile({ ...user.user_profile })
  }, [])

  const handleSubmit = () => {
    dispatch({
      type: constants.UPDATE_USER_PROFILE,
      data: { ...user, user_profile: userProfile },
      callback: () => setIsEditable(false)
    })
  }

  return (
    <>
      <div className="content-box profile-information">
        <div className="header d-flex justify-content-between">
          <h2 style={{lineHeight:2}}>Profile Information</h2>
          {!isEditable &&
            <Button onClick={() => setIsEditable(!isEditable)} className="edit-btn">
              Edit
            </Button>
          }
        </div>
        <div className="form">
          <h4 className="checkbox-title">Preferred Interest </h4>
          <Form>
            <Row>
              {interest.map((item, index) => (
                <Col key={index} md="3">
                  <FormGroup check inline>
                    <div
                      className={`d-flex justify-content-between align-items-center ${isEditable ? ' checkBox-red' : 'checkBox-grey'}`}
                    >
                      <Label check>
                        <Input
                          type="checkbox"
                          onClick={() => setUserProfile( { ...userProfile, [item.key]: !userProfile[item.key] })}
                          checked={userProfile[item.key]}
                        />
                        <span className="form-check-sign" />
                        <span className="form-checklabel">{item.title}</span>
                      </Label>
                    </div>
                  </FormGroup>
                </Col>
              ))}
            </Row>
          </Form>
        </div>
      </div>
      {isEditable &&
        <div className="buttons d-flex justify-content-end">
          <Button
            style={{
              backgroundColor: "#fff",
              color: "#F01716",
              border: "1px solid #F01716",
              borderRadius: "8px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
            onClick={() =>{
              setUserProfile({ ...user.user_profile })
              setIsEditable(!isEditable)}}
            disabled={requesting}
          >
            Cancel
          </Button>
          <Button
            className='savebtn'
            disabled={requesting}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </div>
      }
    </>
  )
}

export default ProfileInformation

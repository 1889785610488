import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FALUIRE,
  CREATE_USERS_REQUEST,
  CREATE_USERS_SUCCESS,
  CREATE_USERS_FALUIRE,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FALUIRE,
  PAGINATION_HANDLING,
  OFFSET_HANDLING,
  SEARCH_USER,
  SEARCH_USER_FALUIRE,
  SEARCH_USER_SUCCESS,
  FILTER_USER,
  FILTER_USER_FALUIRE,
  FILTER_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SUSPEND_USER,
  GET_PREFERRED_INTEREST,
  GET_PREFERRED_INTEREST_SUCCESS,
  GET_PREFERRED_INTEREST_FAILURE,
  RESET_ALL_FILTER_DATA
} from "./types"

const initialState = {
  usersList: false,
  requesting: false,
  createRequesting: false,
  updateRequesting: false,
  error: {},
  pageValue: 1,
  offSet: 10,
  searchRequesting: false,
  filterRequesting: false,
  searchedUser: false,
  filteredUser: false,
  deleteRequesting: false,
  deletedUser: false,
  suspendRequesting: false,
  preferredData: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        requesting: false,
        usersList: action.data
      }

    case GET_ALL_USERS_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    case CREATE_USERS_REQUEST:
      return {
        ...state,
        createRequesting: true
      }

    case CREATE_USERS_SUCCESS:
      return {
        ...state,
        createRequesting: false
      }

    case CREATE_USERS_FALUIRE:
      return {
        ...state,
        createRequesting: false,
        error: action.data
      }

    case UPDATE_USERS_REQUEST:
      return {
        ...state,
        updateRequesting: true
      }

    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        updateRequesting: false,
        searchedUser: action.data
      }

    case UPDATE_USERS_FALUIRE:
      return {
        ...state,
        updateRequesting: false,
        error: action.data
      }

    case PAGINATION_HANDLING:
      return {
        ...state,
        pageValue: action.pageValue
      }

    case OFFSET_HANDLING:
      return {
        ...state,
        offSet: action.offSet
      }

    case SEARCH_USER:
      return {
        ...state,
        searchRequesting: true
      }

    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        searchRequesting: false,
        usersList: action.data
      }

    case SEARCH_USER_FALUIRE:
      return {
        ...state,
        searchRequesting: false,
        usersList: false
      }

    case FILTER_USER:
      return {
        ...state,
        filterRequesting: true
      }

    case FILTER_USER_SUCCESS:
      return {
        ...state,
        filterRequesting: false,
        // filteredUser: action.data
        usersList : action.data
      }

    case FILTER_USER_FALUIRE:
      return {
        ...state,
        filterRequesting: false,
        // filteredUser: [],
        searchedUser: []
      }

    case RESET_ALL_FILTER_DATA:
      return {
        ...state,
        filteredUser: [],
        searchedUser: []
      }

    case DELETE_USER:
      return {
        ...state,
        deleteRequesting: true
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteRequesting: false,
        deletedUser: action.data
      }

    case DELETE_USER_FAILURE:
      return {
        ...state,
        deleteRequesting: false,
        deletedUser: false
      }

      case SUSPEND_USER:
        return {
          ...state,
          suspendRequesting: true
        }
  
      case DELETE_USER_SUCCESS:
        return {
          ...state,
          suspendRequesting: false
        }
  
      case DELETE_USER_FAILURE:
        return {
          ...state,
          suspendRequesting: false
        }

    case GET_PREFERRED_INTEREST:
      return {
        ...state,
        preferdDataRequest: true,
        preferredData: false
      }

    case GET_PREFERRED_INTEREST_SUCCESS:
      return {
        ...state,
        preferdDataRequest: false,
        preferredData: action.data
      }

    case GET_PREFERRED_INTEREST_FAILURE:
      return {
        ...state,
        preferdDataRequest: false,
      }

    default:
      return state
  }
}

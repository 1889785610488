import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FALUIRE,
  CREATE_USERS_REQUEST,
  CREATE_USERS_SUCCESS,
  CREATE_USERS_FALUIRE,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FALUIRE,
  PAGINATION_HANDLING,
  OFFSET_HANDLING,
  FILTER_USER,
  FILTER_USER_SUCCESS,
  FILTER_USER_FALUIRE,
  SEARCH_USER,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FALUIRE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SUSPEND_USER,
  SUSPEND_USER_SUCCESS,
  SUSPEND_USER_FAILURE,
  GET_PREFERRED_INTEREST,
  GET_PREFERRED_INTEREST_SUCCESS,
  GET_PREFERRED_INTEREST_FAILURE,
  RESET_ALL_FILTER_DATA
} from "./types"

export const usersListRequest = data => ({
  type: GET_ALL_USERS_REQUEST,
  data
})

export const usersListSuccess = data => ({
  type: GET_ALL_USERS_SUCCESS,
  data
})

export const usersListFaluire = data => ({
  type: GET_ALL_USERS_FALUIRE,
  data
})

export const createUserRequest = (data, toggle) => ({
  type: CREATE_USERS_REQUEST,
  data,
  toggle
})

export const createUserSuccess = (data, toggle) => ({
  type: CREATE_USERS_SUCCESS,
  data,
  toggle
})

export const createUserFaluire = data => ({
  type: CREATE_USERS_FALUIRE,
  data
})

export const updateUserRequest = (data, modalVisible, offset) => ({
  type: UPDATE_USERS_REQUEST,
  data,
  modalVisible,
  offset
})

export const updateUserSuccess = data => ({
  type: UPDATE_USERS_SUCCESS,
  data
})

export const updateUserFaluire = data => ({
  type: UPDATE_USERS_FALUIRE,
  data
})

export const paginationControl = pageValue => ({
  type: PAGINATION_HANDLING,
  pageValue
})

export const offSetControl = offSet => ({
  type: OFFSET_HANDLING,
  offSet
})

export const filterUsers = (data, offset) => ({
  type: FILTER_USER,
  data,
  offset
})

export const filterUsersSuccess = (data, setIsToggle) => ({
  type: FILTER_USER_SUCCESS,
  data,
  setIsToggle
})

export const filterUsersFailure = data => ({
  type: FILTER_USER_FALUIRE,
  data
})

export const searchUsers = (data, setIsToggle) => ({
  type: SEARCH_USER,
  data,
  setIsToggle
})

export const searchUsersSuccess = (data, setIsToggle) => ({
  type: SEARCH_USER_SUCCESS,
  data,
  setIsToggle
})

export const searchUsersFailure = data => ({
  type: SEARCH_USER_FALUIRE,
  data
})

export const deleteUser = (data, toggle) => ({
  type: DELETE_USER,
  data,
  toggle
})

export const deleteUserSuccess = (data, toggle) => ({
  type: DELETE_USER_SUCCESS,
  data,
  toggle
})

export const deleteUserFailure = data => ({
  type: DELETE_USER_FAILURE,
  data
})

export const suspendUser = (data, setModal1, offset) => ({
  type: SUSPEND_USER,
  data,
  setModal1,
  offset
})

export const suspendUserSuccess = data => ({
  type: SUSPEND_USER_SUCCESS,
  data
})

export const suspendUserFailure = () => ({
  type: SUSPEND_USER_FAILURE
})

export const getPreferredInterest = data => ({
  type: GET_PREFERRED_INTEREST,
  data
})

export const getPreferredInterestSuccess = data => ({
  type: GET_PREFERRED_INTEREST_SUCCESS,
  data
})

export const getPreferredInterestFaluire = data => ({
  type: GET_PREFERRED_INTEREST_FAILURE,
  data
})

export const resetAllFilterData = () => ({
  type: RESET_ALL_FILTER_DATA
})

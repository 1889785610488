import React, { useState } from "react"
import "../style.scss"

const PrivacyPolicy = () => {
  return (
    <>
      <div className="feedback">
        <div className=" content-box">
          <div className="header d-flex justify-content-between">
            <h2>Privacy Policy</h2>
          </div>
          <div className="termsdiscription">
            <p>
              This policy applies to websites, apps or other online services
              provided by PRC Macro Limited (which will hereby be referred to as
              “PRC MACRO”.) PRC MACRO requires authorized users to provide
              current and accurate personal information in compliance with the
              law. This policy applies to all users and customers or anyone that
              is ordering, registering or interacting with any product or
              service from PRC MACRO or its affiliates including but not limited
              to attendees, subscribers, registrants, website users, etc.
            </p>

            <p>
              PRC MACRO is committed to protecting your privacy and the
              information you provide in a manner that is safe, secure and
              professional. PRC MACRO and its employees are committed to
              safeguarding that information.
            </p>

            <h7 style={{ fontWeight: "bold" }}>Safeguarding Information</h7>

            <p>
              PRC MACRO collects non-public customer data for evaluation,
              registration, licensing or related consulting services. We also
              create internal lists of such data. Customer’s information
              collected by PRC MACRO will be kept so long as their account
              remains active. If an account becomes inactive, information may be
              kept for at least six years or for such period of time as may be
              required by legal and regulatory requirements.
            </p>

            <p>
              Customers have the right to access, amend and rectify their
              personal data, to lodge a complaint with a supervising authority,
              the right to be forgotten, to restrict processing and to object to
              processing whereby personal data is being processed for direct
              marketing purposes. If a customer wishes to amend their consent,
              they can email their request to PRC Macro at research@prcmacro.com
              at any time. The company will respond to your written request to
              exercise these rights as soon as is practicable. PRC MACRO does
              not sell personal data to third parties and strictly adheres to
              prohibitions on the disclosure of any client information contained
              in its commercial agreements.
            </p>

            <p>
              We take information security seriously and have policies and
              procedures in place to make sure your information remains safe. If
              PRC MACRO suffers a personal data breach, affected customers will
              be notified of the breach as soon as is practical.
            </p>

            <h7 style={{ fontWeight: "bold" }}>
              Sharing Nonpublic Personal and Financial Information
            </h7>

            <p>
              PRC MACRO is committed to the protection and privacy of its
              customers’ and consumers’ personal information. PRC MACRO will not
              share such information with non-affiliated third parties except:
            </p>

            <li>When required to maintain or service a customer account;</li>

            <li>To resolve customer disputes or inquiries;</li>

            <li>
              With persons acting in a fiduciary or representative capacity on
              behalf of the customer;
            </li>

            <li>
              In connection with a sale or merger of PRC MACRO’s business;
            </li>
            <li>
              To protect against or prevent actual or potential fraud, identity
              theft, unauthorized transactions, claims or other liability;
            </li>
            <li>
              To comply with federal, state or local laws, rules and other
              applicable legal requirements;
            </li>
            <li>
              In any circumstances with the customer’s instruction or consent.
            </li>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy

import React, { useEffect, useState } from "react"

import Images from "utils/Images"
import { Input } from "reactstrap"

import {
  paginationControl,
  offSetControl
} from "Containers/UsersList/redux/actions"
import { connect } from "react-redux"

const Pagination = props => {
  const { totalCount, offset, setOffset, refresh, selectPageValue, setSelectPageValue } = props
  const pages = Math.ceil(totalCount / 10)

  const handleNextButton = () => {
    if (selectPageValue >= pages) {
      return 0
    } else {
      setSelectPageValue(Number(selectPageValue) + 1)
      setOffset(offset + 10)
    }
  }
  const handleBackButton = () => {
    if (selectPageValue === 1) {
      return 0
    } else {
      if (offset <= 0) {
        setOffset(10)
      } else {
        setOffset(offset - 10)
      }
      setSelectPageValue(Number(selectPageValue) - 1)
    }
  }

  const renderStyle = (isDisabled) => {
    return {
      border: `1px solid ${isDisabled ? '#EAEAEA' : '#F01716'}`,
      height: 43,
      width: 43,
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: 'pointer'
    }
  }

  return (
    <div
      style={{
        display: "flex",
        padding: 10,
        backgroundColor: "white",
        borderRadius: "8px",
        alignItems: "center",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
        marginBottom: 20,
        height: 63,
        width: 322
      }}
    >
      <div
        onClick={Number(selectPageValue) === 1 ? null : handleBackButton}
        style={renderStyle(Number(selectPageValue) === 1)}
      >
        <img
          alt=""
          style={{
            height: 16
          }}
          src={Number(selectPageValue) === 1 ? Images.leftp : Images.left}
        />
      </div>
      <div style={{
        backgroundColor: '#F2F2F2',
        borderRadius: "8px",
        width: "148",
        marginLeft: "18px",
        marginRight: "18px",
      }}>
        <div>
          <select
            className="mx-3"
            id="exampleSelect"
            name="select"
            type="select"
            style={{
              height: "43px",
              width: "148px",
              backgroundColor: "#F2F2F2",
              borderRadius: "8px",
              border: "0px",
              cursor:"pointer",
              outline:"none"
            }}
            onChange={e => {
              setSelectPageValue(e.target.value)
              if (e.target.value * 10 - 10 < 0) {
                setOffset(10)
              } else {
                setOffset(e.target.value * 10 - 10)
              }
            }}
            value={selectPageValue}
          >
            {totalCount &&
              Array(parseFloat(pages))
                .fill()
                .map((_, i) => (
                  <option
                    key={i}
                    value={i === 0 ? i + 1 : i + 1}
                  >
                    {`Page ${i + 1}`}
                  </option>
                ))}
          </select>
        </div>
      </div>
      <div
        onClick={Number(selectPageValue) === pages ? null : handleNextButton}
        style={renderStyle(Number(selectPageValue) === pages)}
      >
        <img
          alt=""
          style={{
            height: 16
          }}
          src={Number(selectPageValue) === pages ? Images.right : Images.rightp}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  pageValue: state.UsersList.pageValue,
  offSet: state.UsersList.offSet
})

const mapDispatchToProps = dispatch => ({
  paginationControl: data => dispatch(paginationControl(data)),
  offSetControl: data => dispatch(offSetControl(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)

import React, { useState } from "react"

import PageWrapper from "WebVisual/PageWrapper"
import ClientCall from "WebVisual/Components/ClientCalls"

const ClientCalls = () => {
  const [search, setSearch] = useState("")

  return (
    <PageWrapper onChange={setSearch} title="Client Calls">
      <ClientCall search={search} />
    </PageWrapper>
  )
}

export default ClientCalls

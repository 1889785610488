import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_END_PROCESS } from "./types"

const initialState = {
  requesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case RESET_PASSWORD_END_PROCESS:
      return {
        ...state,
        requesting: false
      }

    default:
      return state
  }
}

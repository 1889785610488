import React, { useState } from "react"

import PageWrapper from 'WebVisual/PageWrapper'
import ArticleCard from "WebVisual/Components/ArticleCard"

const Home = () => {
  const [search, setSearch] = useState('')

  return (
    <PageWrapper onChange={setSearch} title="Home">
      <ArticleCard search={search} />
    </PageWrapper>
  )
}

export default Home

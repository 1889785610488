import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FALUIRE
} from "./types"

const initialState = {
  error: false,
  requesting: false,
  passData: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
        error: false,
        passData: action.data
      }

    case CHANGE_PASSWORD_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}

import React, { useState } from "react"
import {
  Button,
  Col,
  Dropdown,
  DropdownToggle,
  Row
} from "reactstrap"
// reactstrap components

import Images from "utils/Images"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import DateFilterComponent from "../DateFilterComponent"

const FilterComponent = props => {
  const [dateSelected, setDateSelected] = useState(false)

  const onAction = (type, params) => {
    if (props.onApplyFilter) {
      setDateSelected(Boolean(params))
      props.onApplyFilter(type, params)
    }
  }

  return (
    <Row className="justify-content-between filtere-wrraper">
      <Col md="6"><p>{props?.listFound}</p></Col>

      <Col md="6" className="d-flex justify-content-end dateFilter-wrraper">
        <Dropdown
          isOpen={props.isDateToggle}
          toggle={() => props.setIsDateToggle(!props.isDateToggle)}
        >
          <DropdownToggle
            aria-haspopup={true}
            color="default"
            data-toggle="dropdown"
            id="navbarDropdownMenuLink"
            nav
            className="dropdownToggle"
          >
            <div className="dateCard">
              <div className="dateWrap">
                <img src={Images.calander} alt="" />
                <DatePicker
                  placeholderText={dateSelected ? 'Selected' : 'Select date'}
                  className="form-Data"
                  dropdownMode="select"
                  dateFormat="d MMM, yyyy"
                  closeOnScroll={true}
                  locale="hu"
                  disabled
                />
              </div>
            </div>
          </DropdownToggle>

          <DateFilterComponent
            requesting={false}
            onApplyFilter={(type, params) => onAction(type, params)}
          />
        </Dropdown>
        <div className="filterBox">
          <Dropdown
            isOpen={props.isToggle}
            toggle={() => props.setIsToggle(!props.isToggle)}
          >
            <DropdownToggle
              aria-haspopup={true}
              color="default"
              data-toggle="dropdown"
              id="navbarDropdownMenuLink"
              nav
              className="dropdownToggle"
            >
              <Button
                onClick={() => {
                  props.setIsToggle(!props.isToggle)
                }}
                className=" text-capitalize filterBtn"
              >
                <img alt="" src={Images.filterDark} />
                Filter
              </Button>
            </DropdownToggle>
            <DateFilterComponent
              requesting={false}
              categories
              onApplyFilter={(type, params) => props.onApplyFilter && props.onApplyFilter(type, params)}
            />
          </Dropdown>
        </div>
      </Col>
    </Row>
  )
}

export default FilterComponent

import React, { useState } from "react"
import Sidebar from "WebVisual/Sidebar"
import Header from "WebVisual/Header"

import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.scss"

const PageWrapper = ({ children, onChange, title }) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true)
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen)

  return (
    <div className="Page-Layout position-relative">
      <div className="Header">
        <Header toggle={toggleSidebar} onChange={onChange} title={title} />
      </div>
      <div className="Content d-flex">
        <div className="Page-SideBar">
          <Sidebar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        </div>
        <div className="flex-grow-1 page-body">{children}</div>
      </div>
    </div>
  )
}

export default PageWrapper

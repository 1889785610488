import React, { useState } from "react"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Spinner
} from "reactstrap"

import useForm from "utils/useForm"
import validator from "utils/validation"

import { Link } from "react-router-dom"

import Images from "utils/Images"

import { connect } from "react-redux"
import { loginRequest } from "./redux/actions"

const SignIn = props => {
  const { requesting } = props;
  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    email: {
      required: true,
      validator: validator.email
    },
    password: {
      required: true
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const onLogin = () => {
    const data = {
      username: state.email.value,
      password: state.password.value
    }
    props.loginRequest(data)
  }

  const [viewPassword, setViewPassword] = useState(false)
  const [emailVerification, setEmailVerification] = useState(false)
  return (
    <div>
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="6" md="8" sm="">
              <Form action="" className="form" method="">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 50
                  }}
                >
                  <img
                    alt=""
                    style={{
                      height: "64px",
                      width: "64px",
                      borderRadius: 0,
                      marginRight: "13px",
                      marginTop: -3
                    }}
                    src={Images.cardLogo}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Card className="card-login w-75 p-0" style={{ padding: 0 }}>
                    <CardBody
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 23
                      }}
                    >
                      <FormGroup>
                        <p
                          style={{
                            color: "#000000",
                            // fontFamily: "Open Sans",
                            fontWeight: "600",
                            fontSize: 20,
                            textAlign: "center",
                            marginTop: 0
                          }}
                        >
                          Sign In
                        </p>
                      </FormGroup>
                      <Form action="" className="form" method="">
                        <InputGroup
                          className="no-border"
                          style={{
                            border: "1.5px solid #808080",
                            borderRadius: "6px"
                          }}
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{ backgroundColor: "white" }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "15px",
                                  width: "19px"
                                }}
                                email_logo="true"
                                src={Images.emailLogo}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Type email"
                            type="email"
                            style={{
                              backgroundColor: "white",
                              height: "52px",
                              border: "3px solid white"
                            }}
                            onChange={e => {
                              handleOnChange("email", e.target.value)

                              if (validator.email.regEx.test(e.target.value)) {
                                setEmailVerification(true)
                              } else {
                                setEmailVerification(false)
                              }
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{ backgroundColor: "white" }}
                            >
                              {emailVerification && (
                                <img
                                  alt=""
                                  style={{
                                    height: "9px",
                                    width: "11px"
                                  }}
                                  email_logo="true"
                                  src={Images.tick}
                                />
                              )}
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {state.email.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              textAlign: "left",
                              marginTop: -6
                            }}
                          >
                            {state.email.error}
                          </label>
                        )}
                        <InputGroup
                          className="no-border"
                          style={{
                            border: "1.5px solid #808080",
                            borderRadius: "6px"
                          }}
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{ backgroundColor: "white" }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "14px"
                                }}
                                email_logo="true"
                                src={Images.lock}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Type password"
                            type={viewPassword ? " email" : "password"}
                            style={{
                              backgroundColor: "white",
                              height: "52px",
                              border: "3px solid white"
                            }}
                            // secureTextEntry={false}
                            onChange={e =>
                              handleOnChange("password", e.target.value)
                            }
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{ backgroundColor: "white" }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "11px",
                                  width: "16px"
                                }}
                                email_logo="true"
                                src={Images.eye}
                                onClick={() => setViewPassword(!viewPassword)}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Form>
                    </CardBody>
                    <CardFooter style={{}}>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-evenly",
                          marginLeft: 10,
                          marginRight: 10
                        }}
                      >
                        <Button
                          className="mr-md-n2 text-capitalize"
                          style={{
                            backgroundColor: "#F01716",
                            height: 46,
                            // fontFamily: "Submit",
                            fontWeight: "700",
                            fontSize: "16px",
                            borderRadius: "6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            width: 190
                          }}
                          onClick={onLogin}
                          disabled={disable}
                        >
                          {requesting ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Sign In"
                          )}
                        </Button>
                      </div>
                      <hr
                        style={{
                          maxWidth: 150,
                          marginBottom: 28,
                          marginTop: "38px"
                        }}
                      />

                      <p
                        style={{
                          // fontFamily: "Open Sans",
                          fontSize: 14,
                          fontWeight: "400",
                          textAlign: "center",
                          margin: 0
                        }}
                      >
                        Don’t Remember Password?
                      </p>
                      <Link
                        to="/auth/forgot-password"
                        style={{ textDecoration: "none" }}
                      >
                        <p
                          style={{
                            // fontFamily: "Open Sans",
                            fontSize: 14,
                            fontWeight: "400",
                            textAlign: "center",
                            color: "#F01716",
                            margin: 0,
                            paddingBottom: 15
                          }}
                        >
                          Forgot Password
                        </p>
                      </Link>
                    </CardFooter>
                  </Card>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/forgotBg.png")})`,
            alt: ""
          }}
        ></div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  userData: state.SignIn.userData,
  requesting: state.SignIn.requesting
})

const mapDispatchToProps = dispatch => ({
  loginRequest: data => dispatch(loginRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(SignIn)

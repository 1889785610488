import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Input, Button, Nav, NavItem } from "reactstrap"
import search from "../../assets/img/searchIcon.png"
import setting from "../../assets/img/setting.png"
import redSettings from "../../assets/img/redSettings.png"
import redBellicon from "../../assets/img/redBellicon.png"
import bell from "../../assets/img/bell.png"
import { NavLink, useLocation } from "react-router-dom"

import { constants } from '../Components/Notifications/redux'

import "./style.scss"

const Header = ({ title = "Screen Name", toggle, onChange }) => {
  const dispatch = useDispatch()
  const [notify, setNotify] = useState(null)
  const stateNotify = useSelector(state => state.VisualNotificationReducer)

  useEffect(() => {
    if (stateNotify && stateNotify?.notifications?.length) {
      let filterD = stateNotify.notifications.filter(item => !item.is_read)
      setNotify(filterD)
    }
  }, [stateNotify])

  useEffect(() => {
    dispatch({ type: constants.GET_NOTIFICATION })
  }, [])

  return (
    <div className="userHeader justify-content-between">
      <div className="screenName d-flex align-items-center">
        <Button onClick={toggle} className="toggler-btn">
          <span className=" navbar-toggler-bar bar1"></span>
          <span className="navbar-toggler-bar bar2"></span>
          <span className="navbar-toggler-bar bar3"></span>
        </Button>
        <p>{title}</p>
      </div>
      {onChange && (
        <div className="searchBar d-flex align-items-center position-relative">
          <Input
            className="searchInput"
            placeholder="Search ..."
            type="text"
            onChange={e => onChange && onChange(e.target.value)}
          />
          <img className="searchIcon" src={search} alt="search" />
        </div>
      )}
      <div className="settingBox">
        <Nav>
          <NavItem>
            <NavLink to="/user/settings">
              <Button
                className="setting-btn d-flex align-items-center"
                style={{ height: 40 }}
              >
                <img
                  className=""
                  src={
                    useLocation().pathname === "/user/settings"
                      ? redSettings
                      : setting
                  }
                  alt="sett"
                />
                <p
                  style={{
                    color:
                      useLocation().pathname === "/user/settings"
                        ? "red"
                        : null,
                    marginTop: 17
                  }}
                >
                  SETTINGS
                </p>
              </Button>
            </NavLink>
          </NavItem>
        </Nav>
        <div className="notifyBox">
          <NavLink to="/user/notifications">
            <Button className="notify-btn d-flex align-items-center justify-content-center">
              <img className="" src={useLocation().pathname === "/user/notifications" ? redBellicon : bell} alt="sett" />
              {notify && notify?.length ?
                <div className="notify-card">
                  <p style={{ fontSize: 10 }}>{notify && notify?.length}</p>
                </div>
                : <></>
              }
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Header

// export const EDIT_MODAL = "EDIT_MODAL"
// export const ADD_MODAL = "ADD_MODAL"
export const GET_ALL_CLIENT_CALLS = "GET_ALL_CLIENT_CALLS"
export const GET_ALL_CLIENT_CALLS_SUCCESS = "GET_ALL_CLIENT_CALLS_SUCCESS"
export const GET_ALL_CLIENT_CALLS_FALUIRE = "GET_ALL_CLIENT_CALLS_FALUIRE"

export const CREATE_CLIENT_CALL = "CREATE_CLIENT_CALL"
export const CREATE_CLIENT_CALL_SUCCESS = "CREATE_CLIENT_CALL_SUCCESS"
export const CREATE_CLIENT_CALL_FALUIRE = "CREATE_CLIENT_CALL_FALUIRE"

export const UPDATE_CLIENT_CALL = "UPDATE_CLIENT_CALL"
export const UPDATE_CLIENT_CALL_SUCCESS = "UPDATE_CLIENT_CALL_SUCCESS"
export const UPDATE_CLIENT_CALL_FALUIRE = "UPDATE_CLIENT_CALL_FALUIRE"

export const DELETE_CLIENT_CALL = "DELETE_CLIENT_CALL"
export const DELETE_CLIENT_CALL_SUCCESS = "DELETE_CLIENT_CALL_SUCCESS"
export const DELETE_CLIENT_CALL_FALUIRE = "DELETE_CLIENT_CALL_FALUIRE"

export const FILTER_CLIENT_CALL = "FILTER_CLIENT_CALL"
export const FILTER_CLIENT_CALL_SUCCESS = "FILTER_CLIENT_CALL_SUCCESS"
export const FILTER_CLIENT_CALL_FALUIRE = "FILTER_CLIENT_CALL_FALUIRE"

export const SEARCH_CLIENT_CALL = "SEARCH_CLIENT_CALL"
export const SEARCH_CLIENT_CALL_SUCCESS = "SEARCH_CLIENT_CALL_SUCCESS"
export const SEARCH_CLIENT_CALL_FALUIRE = "SEARCH_CLIENT_CALL_FALUIRE"

export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE"

export const PAGINATION_HANDLING = "PAGINATION_HANDLING"

import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_END_PROCESS } from "./types"

export const resetRequest = data => ({
  type: RESET_PASSWORD_REQUEST,
  data
})

export const resetEndProcess = () => ({
  type: RESET_PASSWORD_END_PROCESS
})

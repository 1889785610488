import React from "react"

import PageWrapper from 'WebVisual/PageWrapper'
import Settings from 'WebVisual/Components/Settings'

const Setting = () => {
  return (
    <PageWrapper title="Settings">
      <Settings />
    </PageWrapper>
  )
}

export default Setting

import React, { useEffect } from "react"
import { connect } from "react-redux"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Col,
  Row,
  Spinner,
  CardHeader,
  CardText,
  Label
} from "reactstrap"

import useForm from "utils/useForm"
import validator from "utils/validation"
import { changeEmailFaluire, changeEmailRequest } from "./redux/actions"

const ChangeEmail = props => {
  const { requesting, backendError, emailData } = props

  useEffect(() => {
    emailData && setState(stateSchema)
    // eslint-disable-next-line
  }, [emailData])

  const onChangeEmail = () => {
    props.changeEmailFaluire(false)
    const userId = sessionStorage.getItem("userId")
    const data = {
      id: userId,
      email: state.email.value
    }

    props.changeEmailRequest(data)
  }

  const stateSchema = {
    oldPass: {
      value: "",
      error: ""
    },
    email: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    oldPass: {
      required: false
    },
    email: {
      required: true,
      validator: validator.email
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  return (
    <div>
      <div className="login-page">
        <Container>
          <div style={{ marginTop: "50px" }}></div>
          <Card className="my-2" style={{}}>
            <CardHeader
              style={{
                color: "#4A5981",
                fontSize: 20,
                fontWeight: "400"
              }}
            >
              Change Email
            </CardHeader>
            <hr style={{ width: "90%", height: "1px", marginLeft: "20px" }} />
            <CardBody>
              <CardText>
                {/* <Row style={{ justifyContent: "center" }}>
                  <Col md="12">
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                        fontFamily:
                          "Montserrat , Helvetica Neue,  Arial, sans-serif"
                      }}
                      sm="6"
                    >
                      OLD PASSWORD
                    </Label>
                    <FormGroup>
                      <Input
                        placeholder="Type password.."
                        type="password"
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "52px",
                          maxWidth: "320px"
                        }}
                        value={state.oldPass.value}
                        onChange={e =>
                          handleOnChange("oldPass", e.target.value)
                        }
                      />
                    </FormGroup>
                    {backendError && backendError.old_password ? (
                      <Label
                        style={{
                          color: "red",
                          padding: "0px",
                          marginBottom: "7px",

                          fontSize: "12px",
                          fontFamily:
                            "Montserrat , Helvetica Neue,  Arial, sans-serif"
                        }}
                        sm="6"
                      >
                        {backendError.old_password}
                      </Label>
                    ) : state.oldPass.error ? (
                      <label style={{ color: "red", display: "flex" }}>
                        {state.oldPass.error}
                      </label>
                    ) : null}
                  </Col>
                </Row> */}
                <Row style={{ justifyContent: "center" }}>
                  <Col md="12">
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                        fontFamily:
                          "Montserrat , Helvetica Neue,  Arial, sans-serif"
                      }}
                      sm="6"
                    >
                      EMAIL
                    </Label>
                    <FormGroup>
                      <Input
                        placeholder="Type email"
                        type="text"
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "52px",
                          maxWidth: "320px"
                        }}
                        value={state.email.value}
                        onChange={e => handleOnChange("email", e.target.value)}
                      />
                    </FormGroup>
                    {backendError && backendError.new_password ? (
                      <Label
                        style={{
                          color: "red",
                          padding: "0px",
                          marginBottom: "7px",

                          fontSize: "12px",
                          fontFamily:
                            "Montserrat , Helvetica Neue,  Arial, sans-serif"
                        }}
                        sm="6"
                      >
                        {backendError.new_password}
                      </Label>
                    ) : state.email.error ? (
                      <label style={{ color: "red", display: "flex" }}>
                        {state.email.error}
                      </label>
                    ) : null}
                  </Col>
                </Row>
              </CardText>
            </CardBody>
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end"
            }}
          >
            <Button
              style={{
                backgroundColor: "#F01716",
                color: "#FFFF",
                border: "1px solid #F01716",
                borderRadius: "8px",

                // width: "151px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                // height: 50
              }}
              disabled={disable}
              onClick={() => {
                onChangeEmail()
              }}
            >
              {requesting ? <Spinner size="sm" /> : "Change Email"}
            </Button>
          </div>
        </Container>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  requesting: state.ChangeEmail.requesting,
  backendError: state.ChangeEmail.error,
  emailData: state.ChangeEmail.emailData
})

const mapDispatchToProps = dispatch => ({
  //   changePasswordRequest: data => dispatch(changePasswordRequest(data))
  changeEmailRequest: data => dispatch(changeEmailRequest(data)),
  changeEmailFaluire: data => dispatch(changeEmailFaluire(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail)

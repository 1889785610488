import React, { useEffect } from "react"
import toast from "react-hot-toast"
import { connect } from "react-redux"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Col,
  Row,
  Spinner,
  CardHeader,
  CardText,
  Label
} from "reactstrap"

import useForm from "utils/useForm"

import { changePasswordFaluire, changePasswordRequest } from "./redux/actions"

const ChangePassword = props => {
  const { requesting, backendError, passData } = props

  useEffect(() => {
    passData && setState(stateSchema)
  }, [passData])

  const onChangePassword = () => {
    props.changePasswordFaluire(false)
    const data = {
      old_password: state.oldPass.value,
      new_password: state.newPass.value
    }
    if (state.newPass.value === state.confirmPass.value) {
      props.changePasswordRequest(data)
    } else {
      toast.error("Password did't Match")
    }
  }

  const stateSchema = {
    oldPass: {
      value: "",
      error: ""
    },
    newPass: {
      value: "",
      error: ""
    },
    confirmPass: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    oldPass: {
      required: true
    },
    newPass: {
      required: true
    },
    confirmPass: {
      required: true
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  return (
    <div>
      <div className="login-page">
        <Container>
          <div style={{ marginTop: "50px" }}></div>
          <Card className="my-2" style={{}}>
            <CardHeader
              style={{
                color: "#4A5981",
                fontSize: 20,
                fontWeight: "400"
              }}
            >
              Change Password
            </CardHeader>
            <hr style={{ width: "90%", height: "1px", marginLeft: "20px" }} />
            <CardBody>
              <CardText>
                <Row style={{ justifyContent: "center" }}>
                  <Col md="12">
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                        fontFamily:
                          "Montserrat , Helvetica Neue,  Arial, sans-serif"
                      }}
                      sm="6"
                    >
                    OLD PASSWORD
                    </Label>
                    <FormGroup>
                      <Input
                        placeholder="Type password.."
                        type="password"
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "52px",
                          maxWidth: "320px"
                        }}
                        value={state.oldPass.value}
                        onChange={e =>
                          handleOnChange("oldPass", e.target.value)
                        }
                      />
                    </FormGroup>
                    {backendError && backendError.old_password ? (
                      <Label
                        style={{
                          color: "red",
                          padding: "0px",
                          marginBottom: "7px",

                          fontSize: "12px",
                          fontFamily:
                            "Montserrat , Helvetica Neue,  Arial, sans-serif"
                        }}
                        sm="6"
                      >
                        {backendError.old_password}
                      </Label>
                    ) : state.oldPass.error ? (
                      <label style={{ color: "red", display: "flex" }}>
                        {state.oldPass.error}
                      </label>
                    ) : null}
                  </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                  <Col md="12">
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                        fontFamily:
                          "Montserrat , Helvetica Neue,  Arial, sans-serif"
                      }}
                      sm="6"
                    >
                      NEW PASSWORD
                    </Label>
                    <FormGroup>
                      <Input
                        placeholder="Type password.."
                        type="password"
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "52px",
                          maxWidth: "320px"
                        }}
                        value={state.newPass.value}
                        onChange={e =>
                          handleOnChange("newPass", e.target.value)
                        }
                      />
                    </FormGroup>
                    {backendError && backendError.new_password ? (
                      <Label
                        style={{
                          color: "red",
                          padding: "0px",
                          marginBottom: "7px",

                          fontSize: "12px",
                          fontFamily:
                            "Montserrat , Helvetica Neue,  Arial, sans-serif"
                        }}
                        sm="6"
                      >
                        {backendError.new_password}
                      </Label>
                    ) : state.newPass.error ? (
                      <label style={{ color: "red", display: "flex" }}>
                        {state.newPass.error}
                      </label>
                    ) : null}
                    {}
                  </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                  <Col md="12">
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                        fontFamily:
                          "Montserrat , Helvetica Neue,  Arial, sans-serif"
                      }}
                      sm="6"
                    >
                    CONFIRM PASSWORD
                    </Label>
                    <FormGroup>
                      <Input
                        placeholder="Type password.."
                        type="password"
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "52px",
                          maxWidth: "320px"
                        }}
                        value={state.confirmPass.value}
                        onChange={e =>
                          handleOnChange("confirmPass", e.target.value)
                        }
                      />
                    </FormGroup>
                    {state.confirmPass.error && (
                      <label style={{ color: "red", display: "flex" }}>
                        {state.confirmPass.error}
                      </label>
                    )}
                  </Col>
                </Row>
              </CardText>
            </CardBody>
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end"
            }}
          >
            <Button
              style={{
                backgroundColor: "#F01716",
                color: "#FFFF",
                border: "1px solid #F01716",
                borderRadius: "8px",

                // width: "151px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)"
                // height: 50
              }}
              disabled={disable}
              onClick={() => {
                onChangePassword()
              }}
            >
              {requesting ? <Spinner size="sm" /> : " Change Password"}
            </Button>
          </div>
        </Container>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  requesting: state.ChangePassword.requesting,
  backendError: state.ChangePassword.error,
  passData: state.ChangePassword.passData
})

const mapDispatchToProps = dispatch => ({
  changePasswordRequest: data => dispatch(changePasswordRequest(data)),
  changePasswordFaluire: data => dispatch(changePasswordFaluire(data))
  // resetAllStates: () => dispatch(resetAllStates())
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)

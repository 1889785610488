import React, { useState, useEffect } from "react"
import Images from "utils/Images"
import Pagination from "components/Pagination/Pagination"

import { connect } from "react-redux"
import { getAdminFeedbackRequest, replyFeedbackRequest } from "./redux"

import useForm from "utils/useForm"
import validator from "utils/validation"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  Table,
  Spinner
} from "reactstrap"
import ActivityIndicator from "components/ActivityIndicator"

const FeedBack = props => {
  const [modal, setModal] = useState(false)
  const [result, setResult] = useState(false)
  const [offset, setOffset] = useState(0)
  const [selectPageValue, setSelectPageValue] = useState(1)

  const { adminFeedback, requesting, error } = props

  useEffect(() => {
    props.getAdminFeedbackRequest({ offset: 0 })
  }, [])

  useEffect(() => {
    props.getAdminFeedbackRequest({ offset: offset })
  }, [offset])

  // const dummyData = [
  //   {
  //     Email: "heavyrain@example.com",
  //     subject: "subject of feedback goes here.."
  //   },
  //   {
  //     Email: "heavyrain@example.com",
  //     subject: "subject of feedback goes here.."
  //   },
  //   {
  //     Email: "heavyrain@example.com",
  //     subject: "subject of feedback goes here.."
  //   },
  //   {
  //     Email: "heavyrain@example.com",
  //     subject: "subject of feedback goes here.."
  //   },
  //   {
  //     Email: "heavyrain@example.com",
  //     subject: "subject of feedback goes here.."
  //   },
  //   {
  //     Email: "heavyrain@example.com",
  //     subject: "subject of feedback goes here.."
  //   }
  // ]

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    title: {
      value: "",
      error: ""
    },
    message: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    email: {
      // required: true,
      validator: validator.email
    },
    title: {
      // required: true
    },
    message: {
      required: true
    }
  }

  const { state, handleOnChange,disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const feedbackModal = item => {
    setModal(!modal)
    setState(stateSchema)
    if (item) {
      setResult(item)
      handleOnChange("email", result?.email)
      handleOnChange("title", result?.subject)
      // handleOnChange("email", result?.email)
    }
  }

  const onReplyFeedBck = () => {
    const userId = sessionStorage.getItem("userId")
    const data = {
      email: state.email.value,
      title: result.subject ? result.subject : "You have a message from PRC Macro team",
      message: state.message.value,
      user_feedback: result.id,
      admin: Number(userId)
    }
    props.replyFeedbackRequest(data, feedbackModal)
  }

  return (
    <div className="ml-lg-5 mr-lg-5 mr-3 ml-3">
      <Row>
        <Col md="12">
          <Card className="card-plain">
            <CardHeader>
              <Row
                className="d-md-flex m-0"
                style={{
                  justifyContent: "space-between"
                }}
              >
                <CardTitle
                  tag="h4"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "27px",
                    marginTop: "24px",
                    color: "#4A5981"
                  }}
                >
                  Feedback
                </CardTitle>
              </Row>
            </CardHeader>
            <CardBody>
              {requesting && !adminFeedback.results ? (
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: 40
                  }}
                  className="p-0"
                >
                  <ActivityIndicator
                    data={adminFeedback && adminFeedback.results}
                    requesting={requesting}
                  />
                </Row>
              ) : (
                <Table
                  responsive
                  style={{
                    borderTop: "3px solid transparent",
                    tableLayout:"fixed"
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "20px",
                          fontSize: "12px",
                          color: "#939393"
                        }}
                      >
                        EMAIL
                      </th>
                      <th
                        style={{
                          paddingLeft: "20px",
                          fontSize: "12px",
                          color: "#939393"
                        }}
                      >
                     SUBJECT
                      </th>

                      <th
                        style={{
                          paddingRight: "50px",
                          fontSize: "12px",
                          color: "#939393"
                        }}
                        className="text-right"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  {adminFeedback &&
                    adminFeedback?.results &&
                    adminFeedback.results.map((item, i) => {
                      return (
                        <tbody>
                          <tr
                            style={{
                              backgroundColor: "white",
                              marginLeft: "20px",
                              marginRight: "30px",
                              border: "3px solid transparent"
                            }}
                          >
                            <td
                              style={{
                                padding: "20px",
                                borderTopLeftRadius: "10px",
                                borderBottomLeftRadius: "10px",
                                color: "#000000"
                              }}
                            >
                              {item.email}
                            </td>
                            <td
                              style={{
                                padding: "20px",
                                width: "100px",
                                overflow: "hidden",
                                textOverflow: ".....",
                                whiteSpace: "nowrap",
                                textOverflow:"ellipsis",
                                lineClamp: 1,
                                color: item.subject ? "#000000" : "#acaeb0"
                              }}
                            >
                              {item.message ? item.message : "N/A"}
                            </td>

                            <td
                              className="text-right pr-3"
                              style={{
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px"
                              }}
                            >
                              <Button
                                className="m-0 "
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#F01716",
                                  border: "1px solid #F01716",
                                  borderRadius: 8,
                                  alignItems: "center",
                                  boxShadow:
                                    "2px 4px 12px rgba(rgba(0, 0, 0, 0.25)"
                                }}
                                onClick={() => feedbackModal(item)}
                              >
                                <img
                                  alt=""
                                  style={{
                                    height: "10px",
                                    width: "12px",
                                    marginRight: "4px"
                                  }}
                                  src={Images.reply}
                                />
                                Reply
                              </Button>
                            </td>
                          </tr>
                          <tr style={{ height: 14 }} />
                        </tbody>
                      )
                    })}
                </Table>
              )}
              <div className="pt-4 d-flex justify-content-center"></div>
            </CardBody>
          </Card>
          <Row
            style={{ alignItems: "center", justifyContent: "center" }}
            className="p-0"
          >
            {adminFeedback && adminFeedback.count > 10 ? (
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Pagination
                  totalCount={adminFeedback && adminFeedback.count}
                  offset={offset}
                  setOffset={setOffset}
                  selectPageValue={selectPageValue}
                  setSelectPageValue={setSelectPageValue}
                />
              </Row>
            ) : null}
          </Row>
        </Col>
      </Row>
      <Modal
        isOpen={modal}
        toggle={feedbackModal}
        style={{ maxWidth: "762px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <label
            className="p-0 m-0"
            style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}
          >
            Reply Feedback
          </label>
          <img
            alt=""
            onClick={feedbackModal}
            style={{
              height: 15,
              width: 15,
              marginLeft: "20px"
            }}
            src={Images.cross}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mt-md-4" style={{ justifyContent: "center" }}>
            <Col sm="12">
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    EMAIL
                  </Label>
                  <FormGroup>
                    <Input
                      placeholder="heavyraing@example.com "
                      type="text"
                      value={result?.email}
                      style={{
                        backgroundColor: "#F2F2F2",
                        color:"black"
                        // border: "1px solid #000000"
                        // fontWeight:'bold'
                      }}
                      disabled={true}
                      onChange={e => handleOnChange("email", e.target.value)}
                    />
                  </FormGroup>
                  {/* {state.email.error && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        textAlign: "left",
                        marginTop: -6
                      }}
                    >
                      {state.email.error}
                    </label>
                  )} */}
                  {error?.email && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        textAlign: "left",
                        marginTop: -6
                      }}
                    >
                      {error?.email}
                    </label>
                  )}
                </Col>
              </Row>
              {/* <Row style={{ justifyContent: "center", marginTop: 10 }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    TITLE
                  </Label>
                  <FormGroup>
                    <Input
                      placeholder="Title line goes here.. "
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2"
                        // border: "1px solid #000000"
                      }}
                      onChange={e => handleOnChange("title", e.target.value)}
                      value={result.subject}
                      disabled={true}
                    />
                  </FormGroup>
                
                  {error?.title && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        textAlign: "left",
                        marginTop: -6
                      }}
                    >
                      {error?.title}
                    </label>
                  )}
                </Col>
              </Row> */}

              <Row style={{ justifyContent: "center", marginTop: 10 }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    MESSAGE
                  </Label>
                  <FormGroup >
                    <Input
                      disabled
                      placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."
                      type="textarea"
                      style={{
                        backgroundColor: "#F2F2F2",
                        color:"black",
                        height: 145
                      }}
                      value={result?.message}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ justifyContent: "center", marginTop: 10 }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    RESPONSE
                  </Label>
                  <FormGroup>
                    <Input
                      placeholder="Any message."
                      type="textarea"
                      style={{
                        backgroundColor: "#FFF",
                        color:"black",
                        border: "1px solid #000000"
                      }}
                      onChange={e => handleOnChange("message", e.target.value)}
                    />
                  </FormGroup>
                  {state.message.error && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        textAlign: "left",
                        marginTop: -6
                      }}
                    >
                      {state.message.error}
                    </label>
                  )}
                  {error?.message && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        textAlign: "left",
                        marginTop: -6
                      }}
                    >
                      {error?.message}
                    </label>
                  )}
                </Col>
              </Row>
              <hr />
              <Row style={{ justifyContent: "center", marginBottom: 20 }}>
                <Button
                  onClick={feedbackModal}
                  className="mr-lg-3"
                  style={{
                    backgroundColor: "#fff",
                    color: "#F01716",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#F01716",
                    color: "#FFFF",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                  onClick={() => onReplyFeedBck()}
                  disabled={disable || !state.message.value.trim().length}
                >
                  {props.requesting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Send"
                  )}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => ({
  adminFeedback: state.getAdminFeedbackReducer.adminFeedback,
  requesting: state.getAdminFeedbackReducer.requesting,
  error: state.getAdminFeedbackReducer.error
})
const mapDispatchToProps = dispatch => ({
  getAdminFeedbackRequest: data => dispatch(getAdminFeedbackRequest(data)),
  replyFeedbackRequest: (data, feedbackModal) =>
    dispatch(replyFeedbackRequest(data, feedbackModal))
})
export default connect(mapStateToProps, mapDispatchToProps)(FeedBack)

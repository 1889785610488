import React from "react"
import { NavLink } from "react-router-dom"
import { Nav, Collapse } from "reactstrap"
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar"

// import avatar from 'assets/img/faces/ayo-ogunseinde-2.jpg'
// import logo from 'assets/img/react-logo.png'
import Images from "utils/Images"

var ps

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getCollapseStates(props.routes)
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {}
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        }
      }
      return null
    })
    return initialState
  }
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null
      }
      if (prop.collapse) {
        var st = {}
        st[prop["state"]] = !this.state[prop.state]
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault()
                this.setState(st)
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        )
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <i className={prop.icon} /> */}
                <img
                  alt=""
                  src={prop.image}
                  style={{ height: 16, width: 16, marginRight: 16 }}
                />
                <p>{prop.name}</p>
              </div>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      )
    })
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }
  // componentDidMount() {
  //   // if you are using a Windows Machine, the scrollbars will have a Mac look
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.sidebar, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     })
  //   }
  // }
  // componentWillUnmount() {
  //   // we need to destroy the false scrollbar when we navigate
  //   // to a page that doesn't have this component rendered
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy()
  //   }
  // }
  render() {
    const handleLogout = () => {
      sessionStorage.clear()
      setTimeout(() => {
        this.props.history.push("/auth/login")
      }, 1000)
    }
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
        style={{
          backgroundColor: "black",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {/* <div className='logo'>
          <a
            href='https://www.creative-tim.com'
            className='simple-text logo-mini'
          >
            <div className='logo-img'>
              <img src={logo} alt='react-logo' />
            </div>
          </a>
          <a
            href='https://www.creative-tim.com'
            className='simple-text logo-normal'
          >
            Creative Tim
          </a>
        </div> */}

        <div
          className="sidebar-wrapper"
          ref="sidebar"
          style={{ flex: 2.5, border: 0, overflowX: "hidden" }}
        >
          {/* <div className='user'>
            <div className='photo'>
              <img src={avatar} alt='Avatar' />
            </div>
            <div className='info'>
              <a
                href='#pablo'
                data-toggle='collapse'
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  Chet Faker
                  <b className='caret' />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className='nav'>
                  <li>
                    <NavLink to='/admin/user-profile' activeClassName=''>
                      <span className='sidebar-mini-icon'>MP</span>
                      <span className='sidebar-normal'>My Profile</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/admin/user-profile' activeClassName=''>
                      <span className='sidebar-mini-icon'>EP</span>
                      <span className='sidebar-normal'>Edit Profile</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/admin/user-profile' activeClassName=''>
                      <span className='sidebar-mini-icon'>S</span>
                      <span className='sidebar-normal'>Settings</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div> */}
          <Nav>{this.createLinks(this.props.routes)}</Nav>
          {/* <div
            className=""
            style={{
              // flex: 0.2,
              // paddingBottom: 30,
              marginTop: "15px",
              paddingLeft: "24px",
              border: 0,
              // overflow: "hidden"
            }}
          >
            <p
              onClick={handleLogout}
              style={{
                fontSize: 12,
                opacity: 0.7,
                color: "#FFFFFF",
                fontFamily: "Montserrat , Helvetica Neue,  Arial, sans-serif",
                textTransform: "uppercase",
                fontWeight: "600",
                cursor: "pointer"
              }}
            >
              <img
                alt=""
                style={{
                  height: "15px",
                  width: "15px",
                  marginRight: "17px"
                }}
                src={Images.logout}
              />
              Logout
            </p>
          </div> */}
        </div>

        <div
          className="sidebar-wrapper"
          style={{
            flex: 0.2,
            padding: "0px",
            paddingBottom: 30,
            paddingLeft: 30,
            border: 0,
            overflow: "hidden"
          }}
        >
          <img
            alt=""
            style={{
              height: "50px",
              width: "50px"
            }}
            src={Images.cardLogo}
          />
        </div>
      </div>
    )
  }
}

export default Sidebar

import { all, call, put, takeLatest } from "redux-saga/effects"
import { push } from "connected-react-router"

import toast from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import { FORGOT_PASSWORD_REQUEST } from "./types"

// actions
import { forgotEndProcess } from "./actions"

function forgotPasswordAPI(data) {
  const URL = `${BASE_URL}/api/v1/reset/password/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* forgotPassword({ data }) {
  try {
    const response = yield call(forgotPasswordAPI, data)
    yield put(forgotEndProcess())
    toast.success(`${response?.data?.success}`)
    yield put(
      push({
        pathname: "/auth/login"
      })
    )
  } catch (e) {
    let err = e?.response?.data?.error

    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(forgotEndProcess("Connection Error"))
    } else {
      toast.error(err)
      yield put(forgotEndProcess(err))
    }
  }
}

export default all([takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword)])

import {
  GET_ARTICLES,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAILURE,
  PUBLISH_ARTICLES,
  PUBLISH_ARTICLES_SUCCESS,
  PUBLISH_ARTICLES_FAILURE,
  UPDATE_ARTICLES,
  UPDATE_ARTICLES_SUCCESS,
  UPDATE_ARTICLES_FAILURE,
  DELETE_ARTICLES,
  DELETE_ARTICLES_SUCCESS,
  DELETE_ARTICLES_FAILURE,
  FILTER_ARTICLES,
  FILTER_ARTICLES_SUCCESS,
  FILTER_ARTICLES_FALUIRE,
  SEARCH_ARTICLES,
  SEARCH_ARTICLES_SUCCESS,
  SEARCH_ARTICLES_FALUIRE
} from "./types"

export const getArticles = data => ({
  type: GET_ARTICLES,
  data
})

export const getArticlesSuccess = data => ({
  type: GET_ARTICLES_SUCCESS,
  data
})

export const getArticlesFailure = error => ({
  type: GET_ARTICLES_FAILURE,
  error
})

export const publishArticles = (data, toggle) => ({
  type: PUBLISH_ARTICLES,
  data,
  toggle
})

export const publishArticlesSuccess = (data, toggle) => ({
  type: PUBLISH_ARTICLES_SUCCESS,
  data,
  toggle
})

export const publishArticlesFailure = error => ({
  type: PUBLISH_ARTICLES_FAILURE,
  error
})

export const updateArticles = (data, toggle) => ({
  type: UPDATE_ARTICLES,
  data,
  toggle
})

export const updateArticlesSuccess = (data, toggle) => ({
  type: UPDATE_ARTICLES_SUCCESS,
  data,
  toggle
})

export const updateArticlesFailure = error => ({
  type: UPDATE_ARTICLES_FAILURE,
  error
})

export const deleteArticles = (data, toggle) => ({
  type: DELETE_ARTICLES,
  data,
  toggle
})

export const deleteArticlesSuccess = (data, toggle) => ({
  type: DELETE_ARTICLES_SUCCESS,
  data,
  toggle
})

export const deleteArticlesFailure = error => ({
  type: DELETE_ARTICLES_FAILURE,
  error
})

export const filterArticles = (data, setIsToggle) => ({
  type: FILTER_ARTICLES,
  data,
  setIsToggle
})

export const filterArticlesSuccess = (data, setIsToggle) => ({
  type: FILTER_ARTICLES_SUCCESS,
  data,
  setIsToggle
})

export const filterArticlesFailure = data => ({
  type: FILTER_ARTICLES_FALUIRE,
  data
})

export const searchArticles = (data, setIsToggle) => ({
  type: SEARCH_ARTICLES,
  data,
  setIsToggle
})

export const searchArticlesSuccess = (data, setIsToggle) => ({
  type: SEARCH_ARTICLES_SUCCESS,
  data,
  setIsToggle
})

export const searchArticlesFailure = data => ({
  type: SEARCH_ARTICLES_FALUIRE,
  data
})

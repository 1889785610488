import {
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FALUIRE
} from "./types"

const initialState = {
  error: false,
  requesting: false,
  emailData: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        requesting: false,
        error: false,
        emailData: action.data
      }

    case CHANGE_EMAIL_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }

    default:
      return state
  }
}

import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import toast from "react-hot-toast"
import "./style.css"
import {
  FormGroup,
  Modal,
  Input,
  Spinner,
  Button,
  Row,
  Label,
  Col
} from "reactstrap"

import { history } from "Redux/store"
import Images from "utils/Images"
import useForm from "utils/useForm"
import validator from "utils/validation"
import {
  changeEmailRequest,
  changeEmailFaluire
} from "Containers/ChangeEmail/redux/actions"
import { changePasswordFaluire } from "Containers/ChangePassword/redux/actions"
import { changePasswordRequest } from "Containers/ChangePassword/redux/actions"

const Setting = props => {
  const {
    requesting,
    backendError,
    emailData,
    passData,
    changePassbackendError,
    changePassrequesting
  } = props
  const [emailModal, setEmailModal] = useState(false)
  const [changePassModal, setChangePassModal] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    setIsSubmitted(false)
  }, [])
  useEffect(() => {
    // emailData && setState(emailStateSchema)
    // eslint-disable-next-line
  }, [emailData])
  useEffect(() => {
    if (isSubmitted && changePassbackendError && !passData) {
      setChangePassModal(true)
    } else {
      setChangePassModal(false)
    }
  }, [passData])

  const onChangePassword = () => {
    props.changePasswordFaluire(false)
    const data = {
      old_password: state.oldPass?.value,
      new_password: state.newPass?.value
    }
    if (state.newPass?.value === state.confirmPass?.value) {
      props.changePasswordRequest(data)
      setIsSubmitted(true)
    }
    else {
      toast.error("Password did't Match")
    }
  }

  const onChangeEmail = () => {
    props.changeEmailFaluire(false)
    const userId = sessionStorage.getItem("userId")
    const data = {
      id: userId,
      email: state.email?.value
    }

    props.changeEmailRequest(data)
    setTimeout(() => {
      if (!requesting) {
        setEmailModal(false)
      }
    }, 1000)
  }
  const handleLogout = () => {
    sessionStorage.clear()
    setTimeout(() => {
      history.push("/auth/login")
    }, 1000)
  }

  const stateSchema = {
    oldPass: {
      value: "",
      error: ""
    },
    newPass: {
      value: "",
      error: ""
    },
    confirmPass: {
      value: "",
      error: ""
    }
  }

  const emailStateSchema = {
    email: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    oldPass: {
      required: true
    },
    newPass: {
      required: true
    },
    confirmPass: {
      required: true
    }
  }

  const emailValidationStateSchema = {
    email: {
      required: true,
      validator: validator.email
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    emailModal ? emailStateSchema : stateSchema,
    emailModal ? emailValidationStateSchema : validationStateSchema
  )

  return (
    <>
      <div className="setting-wrapper">
        <div className="setting-details">
          <h5 className="setting-title">Account & Settings</h5>
          <div
            onClick={() => {
              setState(emailStateSchema)
              setEmailModal(true)
            }}
            className="setting-list"
          >
            <p>Change Email</p>
            <img src={Images.rightp} />
          </div>
          <div
            onClick={() => {
              setState(stateSchema)
              setChangePassModal(true)
            }}
            className="setting-list"
          >
            <p>Change Password</p>
            <img src={Images.rightp} />
          </div>
          <div onClick={handleLogout} className="setting-list">
            <p>Logout</p>
            <img src={Images.rightp} />
          </div>
        </div>
      </div>

      <Modal isOpen={emailModal} toggle={""} style={{ maxWidth: "444px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px"
          }}
        >
          <label
            className="p-0 m-0"
            style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}
          >
            Change Email
          </label>
          <img
            alt=""
            onClick={() => {
              setState(stateSchema)
              setEmailModal(false)
            }}
            style={{
              height: 15,
              width: 15,
              cursor: "pointer",
              marginLeft: "20px"
            }}
            src={Images.cross}
          />
        </div>
        <Row className="m-0">
          <Col
            md="12"
            style={{
              padding: "0 21px"
            }}
          >
            <Label
              style={{
                paddingLeft: "0px",
                color: "#000000",
                marginBottom: "7px",
                fontWeight: "600",
                fontSize: "12px",
                fontFamily: "Montserrat , Helvetica Neue,  Arial, sans-serif"
              }}
              sm="6"
            >
              EMAIL
            </Label>
            <FormGroup
              style={
                {
                  //  padding:"0 21px"
                }
              }
            >
              <Input
                placeholder="Type email"
                type="text"
                style={{
                  backgroundColor: "#F2F2F2",
                  height: "52px"
                }}
                value={state.email?.value}
                onChange={e => handleOnChange("email", e.target.value)}
              />
            </FormGroup>
            {backendError && backendError.new_password ? (
              <Label
                style={{
                  color: "red",
                  padding: "0px",
                  marginBottom: "7px",

                  fontSize: "12px",
                  fontFamily: "Montserrat , Helvetica Neue,  Arial, sans-serif"
                }}
                sm="6"
              >
                {backendError.new_password}
              </Label>
            ) : state.email?.error ? (
              <label style={{ color: "red", display: "flex" }}>
                {state.email?.error}
              </label>
            ) : null}
          </Col>
        </Row>
        <hr />
        <Row style={{ justifyContent: "center", marginBottom: 20 }}>
          <Button
            className="mr-lg-3"
            style={{
              backgroundColor: "#fff",
              color: "#F01716",
              border: "1px solid #F01716",
              borderRadius: "8px",
              width: "151px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
            onClick={() => {
              setState(stateSchema)
              setEmailModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#F01716",
              color: "#FFFF",
              border: "1px solid #F01716",
              borderRadius: "8px",
              width: "151px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
            onClick={onChangeEmail}
            disabled={disable}
          >
            {requesting ? <Spinner size="sm" /> : "Create"}
          </Button>
        </Row>
      </Modal>
      <Modal isOpen={changePassModal} toggle={""} style={{ maxWidth: "444px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px"
          }}
        >
          <label
            className="p-0 m-0"
            style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}
          >
            Change Password
          </label>
          <img
            alt=""
            onClick={() => {
              setState(stateSchema)
              setChangePassModal(false)
              setIsSubmitted(false)
            }}
            style={{
              height: 15,
              width: 15,
              cursor: "pointer",
              marginLeft: "20px"
            }}
            src={Images.cross}
          />
        </div>
        <Row style={{ justifyContent: "center", margin: 0 }}>
          <Col md="12" style={{ justifyContent: "center", padding: "0 21px" }}>
            <Label
              style={{
                color: "#000000",
                padding: "0px",
                marginBottom: "7px",
                fontWeight: "600",
                fontSize: "12px",
                fontFamily: "Montserrat , Helvetica Neue,  Arial, sans-serif"
              }}
              sm="6"
            >
              OLD PASSWORD
            </Label>
            <FormGroup>
              <Input
                placeholder="Type password.."
                type="password"
                style={{
                  backgroundColor: "#F2F2F2",
                  height: "52px"
                }}
                value={state.oldPass?.value}
                onChange={e => {
                  handleOnChange("oldPass", e.target.value)
                  setIsSubmitted(false)
                }}
              />
            </FormGroup>
            {state.oldPass?.value !=="" && isSubmitted && changePassbackendError && changePassbackendError.old_password ? (
              <Label
                style={{
                  color: "red",
                  padding: "0px",
                  marginBottom: "7px",

                  fontSize: "12px",
                  fontFamily: "Montserrat , Helvetica Neue,  Arial, sans-serif"
                }}
                sm="6"
              >
                {changePassbackendError.old_password}
              </Label>
            ) : state.oldPass?.error ? (
              <label style={{ color: "red", display: "flex" }}>
                {state.oldPass?.error}
              </label>
            ) : null}
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", margin: 0 }}>
          <Col md="12" style={{ justifyContent: "center", padding: "0 21px" }}>
            <Label
              style={{
                color: "#000000",
                padding: "0px",
                marginBottom: "7px",
                fontWeight: "600",
                fontSize: "12px",
                fontFamily: "Montserrat , Helvetica Neue,  Arial, sans-serif"
              }}
              sm="6"
            >
              NEW PASSWORD
            </Label>
            <FormGroup>
              <Input
                placeholder="Type new password.."
                type="password"
                style={{
                  backgroundColor: "#F2F2F2",
                  height: "52px"
                }}
                value={state.newPass?.value}
                onChange={e => handleOnChange("newPass", e.target.value)}
              />
            </FormGroup>
            {changePassbackendError && changePassbackendError.new_password ? (
              <Label
                style={{
                  color: "red",
                  padding: "0px",
                  marginBottom: "7px",

                  fontSize: "12px",
                  fontFamily: "Montserrat , Helvetica Neue,  Arial, sans-serif"
                }}
                sm="6"
              >
                {changePassbackendError.new_password}
              </Label>
            ) : state.newPass?.error ? (
              <label style={{ color: "red", display: "flex" }}>
                {state.newPass?.error}
              </label>
            ) : null}
            {}
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", margin: 0 }}>
          <Col md="12" style={{ justifyContent: "center", padding: "0 21px" }}>
            <Label
              style={{
                color: "#000000",
                padding: "0px",
                marginBottom: "7px",
                fontWeight: "600",
                fontSize: "12px",
                fontFamily: "Montserrat , Helvetica Neue,  Arial, sans-serif"
              }}
              sm="6"
            >
              CONFIRM PASSWORD
            </Label>
            <FormGroup>
              <Input
                placeholder="Confirm your password.."
                type="password"
                style={{
                  backgroundColor: "#F2F2F2",
                  height: "52px"
                }}
                value={state.confirmPass?.value}
                onChange={e => handleOnChange("confirmPass", e.target.value)}
              />
            </FormGroup>
            {state.newPass?.value !== "" &&
            state.confirmPass?.value !== "" &&
            state.newPass?.value !== state.confirmPass?.value ? (
              <label style={{ color: "red", display: "flex" }}>
                Password did't Match
              </label>
            ) : (
              ""
            )}
            {state.confirmPass?.error && (
              <label style={{ color: "red", display: "flex" }}>
                {state.confirmPass?.error}
              </label>
            )}
          </Col>
        </Row>
        <hr />
        <Row style={{ justifyContent: "center", marginBottom: 20 }}>
          <Button
            className="mr-lg-3"
            style={{
              backgroundColor: "#fff",
              color: "#F01716",
              border: "1px solid #F01716",
              borderRadius: "8px",
              width: "151px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
            onClick={() => {
              setState(stateSchema)
              setChangePassModal(false)
              setIsSubmitted(false)
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#F01716",
              color: "#FFFF",
              border: "1px solid #F01716",
              borderRadius: "8px",
              width: "151px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
            // onClick={onChangeEmail}
            disabled={disable}
            onClick={() => {
              onChangePassword()
            }}
          >
            {changePassrequesting ? <Spinner size="sm" /> : "Create"}
          </Button>
        </Row>
      </Modal>
    </>
  )
}

// export default ;
const mapStateToProps = state => ({
  requesting: state.ChangeEmail.requesting,
  backendError: state.ChangeEmail.error,
  emailData: state.ChangeEmail.emailData,

  changePassrequesting: state.ChangePassword.requesting,
  changePassbackendError: state.ChangePassword.error,
  passData: state.ChangePassword.passData
})

const mapDispatchToProps = dispatch => ({
  //   changePasswordRequest: data => dispatch(changePasswordRequest(data))
  changeEmailRequest: data => dispatch(changeEmailRequest(data)),
  changeEmailFaluire: data => dispatch(changeEmailFaluire(data)),
  changePasswordRequest: data => dispatch(changePasswordRequest(data)),
  changePasswordFaluire: data => dispatch(changePasswordFaluire(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Setting)

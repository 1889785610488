import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap"
import "./style.scss"

import Images from "utils/Images"
import "react-datepicker/dist/react-datepicker.css"
import { constants } from "./redux"
import { Link } from "react-router-dom"
import ActivityIndicator from "components/ActivityIndicator"

const typeMapping = {
  'articles': 'Articles',
  'pod_cast': 'Podcast',
  'client_calls': 'Client calls'
}

const Notifications = () => {
  const [isToggle, setIsToggle] = useState(false)
  const [toggleVal, setToggleVal] = useState('All')
  const [notificationData, setNotificationData] = useState([])
  const dispatch = useDispatch()
  const state = useSelector(state => state.VisualNotificationReducer)

  useEffect(() => {
    state.notifications && setNotificationData(state.notifications)
  }, [state]);

  useEffect(() => {
    dispatch({ type: constants.GET_NOTIFICATION })
  }, []);

  const data = [
    { title: 'All', value: '' },
    { title: 'Articles', value: 'articles' },
    { title: 'Podcasts', value: 'pod_cast' },
    { title: 'Client calls', value: 'client_calls' },
  ]

  const selectCategory = (item) => {
    if (item.value) {
      const filterData = `?${item.value}=${item.value}`
      dispatch({ type: constants.FILTER_NOTIFICATION, params: filterData })
    } else {
      dispatch({ type: constants.GET_NOTIFICATION })
    }
    setToggleVal(item.title)
  }

  const markAsRead = (item) => {
    dispatch({
      type: constants.MARK_AS_READ_NOTIFICATION,
      params: item.id,
      data: { is_read: !item.is_read },
      callBack: (success) => success && checkUncheckNotification(item.id)
    })
  }

  const checkUncheckNotification = (id) => {
    const notificationArray = [...notificationData]
    const index = notificationArray.findIndex(item => item.id === id)
    const notification = notificationArray[index]
    notificationArray[index] = { ...notification, is_read: !notification.is_read }
    setNotificationData(notificationArray)
  }

  return (
    <div className="notificationsWrapper">
      <Row className="justify-content-center topBtnWrraper">
        <Col md="10">
          <div className="dropdownWrraper">
            <Link to="/user/home">
              <Button className="backBtn">
                <img className="backIcon-img" src={Images.backIcon} alt="" />
              </Button>
            </Link>
            <div className="">
              <Dropdown isOpen={isToggle} toggle={() => setIsToggle(!isToggle)}>
                <DropdownToggle
                  aria-haspopup={true}
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                  className="dropdownTogle"
                >
                  <Button
                    onClick={() => setIsToggle(!isToggle)}
                    className=" text-capitalize"
                  >
                    {toggleVal}
                    <img src={Images.dropdownIcon} alt="" />
                  </Button>
                </DropdownToggle>
                <DropdownMenu
                  persist
                  aria-labelledby="navbarDropdownMenuLink"
                  right
                  style={{ width: 200, right: 0 }}
                >
                  {data.map((item, i) => (
                    <div key={i}>
                      <DropdownItem
                        className=""
                        onClick={() => selectCategory(item)}
                        style={{ padding: "0 10px" }}
                      >
                        <img src={""} alt="" />
                        <p>{item.title}</p>
                      </DropdownItem>
                      <DropdownItem divider />
                    </div>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="cardDetailsWrapper justify-content-center">
        {state.requesting ? (
          <ActivityIndicator requesting />
        ) : state.requesting === false && notificationData.length < 1 ? (
          <Row className="d-flex justify-content-center align-items-center m-auto">
            <p className="">No content found.</p>
          </Row>
        ) : (
          notificationData.map((item, i) => (
            <Col lg="10" key={i}>
              <Card body className="p-0">
                <CardTitle className="cardTitleWrapper">
                  <p className="userConversation">{item.message}</p>
                  <Row className="categoryWrapper">
                    <p className="category">Category:</p>
                    <p className="categoryType">{typeMapping[item.notification_type]}</p>
                  </Row>
                  <FormGroup check>
                    <div>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={item.is_read}
                          onChange={() => markAsRead(item)}
                        />
                        <span className="form-check-sign" />
                        <span className="checkboxLabel">
                          Mark as read
                        </span>
                      </Label>
                    </div>
                  </FormGroup>
                </CardTitle>
              </Card>
            </Col>
          )))}

      </Row>
    </div>
  )
}

export default Notifications

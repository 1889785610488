import {
  // ADD_MODAL,
  // EDIT_MODAL,
  GET_ALL_CLIENT_CALLS,
  GET_ALL_CLIENT_CALLS_SUCCESS,
  GET_ALL_CLIENT_CALLS_FALUIRE,
  PAGINATION_HANDLING,
  CREATE_CLIENT_CALL,
  CREATE_CLIENT_CALL_SUCCESS,
  CREATE_CLIENT_CALL_FALUIRE,
  UPDATE_CLIENT_CALL,
  UPDATE_CLIENT_CALL_SUCCESS,
  UPDATE_CLIENT_CALL_FALUIRE,
  DELETE_CLIENT_CALL,
  DELETE_CLIENT_CALL_SUCCESS,
  DELETE_CLIENT_CALL_FALUIRE,
  FILTER_CLIENT_CALL,
  FILTER_CLIENT_CALL_SUCCESS,
  FILTER_CLIENT_CALL_FALUIRE,
  SEARCH_CLIENT_CALL,
  SEARCH_CLIENT_CALL_SUCCESS,
  SEARCH_CLIENT_CALL_FALUIRE,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE
} from "./types"

//export const toggleAddModal = data => ({
//   type: ADD_MODAL,
//   data
// })

// export const toggleEditModal = data => ({
//   type: EDIT_MODAL,
//   data
// })

export const getAllClientCalls = data => ({
  type: GET_ALL_CLIENT_CALLS,
  data
})

export const getAllClientCallsSuccess = data => ({
  type: GET_ALL_CLIENT_CALLS_SUCCESS,
  data
})

export const getAllClientCallsFailure = error => ({
  type: GET_ALL_CLIENT_CALLS_FALUIRE,
  error
})

export const createClientCall = (data, toggle) => ({
  type: CREATE_CLIENT_CALL,
  data,
  toggle
})

export const createClientCallSuccess = (data, toggle) => ({
  type: CREATE_CLIENT_CALL_SUCCESS,
  data,
  toggle
})

export const createClientCallFailure = error => ({
  type: CREATE_CLIENT_CALL_FALUIRE,
  error
})

export const updateClientCall = (data, toggle) => ({
  type: UPDATE_CLIENT_CALL,
  data,
  toggle
})

export const updateClientCallSuccess = (data, toggle) => ({
  type: UPDATE_CLIENT_CALL_SUCCESS,
  data,
  toggle
})

export const updateClientCallFailure = error => ({
  type: UPDATE_CLIENT_CALL_FALUIRE,
  error
})

export const deleteClientCall = (data, toggle) => ({
  type: DELETE_CLIENT_CALL,
  data,
  toggle
})

export const deleteClientCallSuccess = (data, toggle) => ({
  type: DELETE_CLIENT_CALL_SUCCESS,
  data,
  toggle
})

export const deleteClientCallFailure = error => ({
  type: DELETE_CLIENT_CALL_FALUIRE,
  error
})

export const filterClientCall = (data, toggle) => ({
  type: FILTER_CLIENT_CALL,
  data,
  toggle
})

export const filterClientCallSuccess = (data, toggle) => ({
  type: FILTER_CLIENT_CALL_SUCCESS,
  data,
  toggle
})

export const filterClientCallFailure = error => ({
  type: FILTER_CLIENT_CALL_FALUIRE,
  error
})

export const searchClientCall = data => ({
  type: SEARCH_CLIENT_CALL,
  data
})

export const searchClientCallSuccess = data => ({
  type: SEARCH_CLIENT_CALL_SUCCESS,
  data
})

export const searchClientCallFailure = error => ({
  type: SEARCH_CLIENT_CALL_FALUIRE,
  error
})

export const getCategory = data => ({
  type: GET_CATEGORY,
  data
})

export const getCategorySuccess = data => ({
  type: GET_CATEGORY_SUCCESS,
  data
})

export const getCategoryFailure = error => ({
  type: GET_CATEGORY_FAILURE,
  error
})

export const paginationControl = data => ({
  type: PAGINATION_HANDLING,
  data
})

import React, { useState } from "react";
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { constants } from '../redux'

import '../style.scss';

const ChangePassword = () => {
    const [currentPassError, setCurrentPassError]=useState("")
    const [newPassError, setNewPassError]=useState("")
    const [repeatPassError, setRepeatPassError]=useState("")

    const dispatch = useDispatch()
    const requesting = useSelector(state => state.VisualSettingsReducer.requesting)
    
    const [password, setPassword] = useState({
        current: "",
        new: "",
        repeat: "",
    });

    const onSubmit = () => {
        setCurrentPassError("")
        setNewPassError("")
        setRepeatPassError("")
        if (password.new !== password.repeat) {
            toast.error("Password doesn't match.")
        } else {
            dispatch({
                type: constants.CHANGE_PASSWORD,
                data: { old_password: password.current, new_password: password.new }
            })
            setPassword({ current: "", new: "", repeat: "" })
        }
    }

    return (
        <>
            <div className="change-password">
                <div className=" content-box">
                    <div className="header d-flex justify-content-between">
                        <h2>Change Password</h2>
                    </div>
                    <div className="form">
                        <Form>
                            <Row>
                                <Col sm='5'>
                                    <FormGroup style={{ marginTop: 15 }}>
                                        <Label>
                                            CURRENT PASSWORD
                                        </Label>
                                        <Input
                                            placeholder="Type password.."
                                            type="password"
                                            value={password.current}
                                            onChange={e => {
                                                setPassword({ ...password, current: e.target.value })
                                                setCurrentPassError("Required field")
                                            }}
                                        />
                                         {password.current ==="" ?
                                        <small style={{color:"red"}}>{currentPassError}</small>:""}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='5'>
                                    <FormGroup style={{ marginTop: 15 }}>
                                        <Label>
                                            NEW PASSWORD
                                        </Label>
                                        <Input
                                            placeholder="Type password.."
                                            type="password"
                                            value={password.new}
                                            onChange={e => {
                                                setPassword({ ...password, new: e.target.value })
                                                setNewPassError("Required field")
                                            }}
                                        />
                                        {password.new ==="" ?
                                        <small style={{color:"red"}}>{newPassError}</small>:""}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm='5'>
                                    <FormGroup style={{ marginTop: 15 }}>
                                        <Label>
                                            REPEAT PASSWORD
                                        </Label>
                                        <Input
                                            placeholder="Type password.."
                                            type="password"
                                            value={password.repeat}
                                            onChange={e => {
                                                setPassword({ ...password, repeat:   e.target.value })
                                                setRepeatPassError("Required field")
                                            }}
                                        />
                                         {password.repeat ==="" ?
                                        <small style={{color:"red"}}>{repeatPassError}</small>:""}
                                        {
                                  ( password.new !=="" && password.repeat !=="" && password.new !== password.repeat )?       
                                    <small style={{color:"red"}}>
                                    Password doesn't match</small>:""
                                        }
                                    </FormGroup>
                                </Col>
                            </Row> 
                        </Form>
                    </div>
                </div>
                <div className="buttons d-flex justify-content-end">
                    <Button
                        className='savebtn'
                        disabled={password.current ==="" || password.new ==="" ||
                         password.repeat ==="" || ( password.new !=="" && password.repeat !=="" && password.new !== password.repeat )}
                        onClick={onSubmit}
                    >
                        Change Password
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ChangePassword
import React from "react"
import { Route, Redirect } from "react-router-dom"
import { compose } from "redux"
import { connect } from "react-redux"
import NotFound from "../Containers/NotFound"
import routes from "../routes"

const RouteGuard = ({
  isProtected = false,
  // accessToken,
  component: Component,
  ...rest
}) => {
  const accessToken = sessionStorage.getItem("prc_authToken")

  return (
    <Route
      {...rest}
      render={props => {
        const isPathExists = routes.find(
          e => `${e.layout}${e.path}` === props.location.pathname
        )
        if (isProtected) {
          return accessToken ? (
            <>
              {isPathExists ? (
                <Component {...props} />
              ) : (
                <Route component={NotFound} />
              )}
            </>
          ) : (
            <Redirect to={{ pathname: "/auth/login" }} />
          )
        } else {
          return accessToken ? (
            <Redirect
              to={{
                pathname: "/"
              }}
            />
          ) : (
            <>
              {isPathExists ? (
                <Component {...props} />
              ) : (
                <Route component={NotFound} />
              )}
            </>
          )
        }
      }}
    />
  )
}

const mapStateToProps = state => ({
  // accessToken: state.signIn.accessToken
  // userDetails: state.signIn.userDetails,
  // timerRef: state.flightSearch.timerRef
})

// const mapStateToProps = createStructuredSelector({
//     user: makeSelectUser(),
// });

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(RouteGuard)

import React from "react"
import "../style.scss"
import { Form } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { constants } from "../redux"

const Notifications = () => {
  const user = useSelector(state => state.SignIn.user)
  const dispatch = useDispatch()
  return (
    <>
      <div className="notifications">
        <div className=" content-box">
          <div className="header d-flex justify-content-between">
            <h2>Notifications</h2>
          </div>
          <div className="form">
            <Form>
              <div className="d-flex justify-content-between swithcBtn">
                <span className="label">
                  Allow Notification from this application
                </span>
                <span className="d-flex">
                  <input
                    type="checkbox"
                    id="switch"
                    checked={user.is_notification_enable}
                    onChange={() =>
                      dispatch({
                        type: constants.UPDATE_NOTIFICATION_STATUS,
                        user
                      })
                    }
                  />
                  <label for="switch">Toggle</label>
                </span>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notifications

import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Spinner
} from "reactstrap"
import { useLocation } from "react-router-dom";
import useForm from "utils/useForm"
import validator from "utils/validation"

import Images from "utils/Images"

import { connect } from "react-redux"
import { resetRequest } from "./redux/actions"

const ResetPassword = props => {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get("token");
  const uid = new URLSearchParams(search).get("uid");

  useEffect(() => {
    if (token && uid) {
      console.log(token, uid);
    } else {
      props.history.replace('/auth/login')
    }
  }, [])

  const stateSchema = {
    newPassword: {
      value: "",
      error: ""
    },
    confirmPassword: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    newPassword: {
      required: true,
      validator: validator.password
    },
    confirmPassword: {
      required: true,
      validator: {
        compare: value => value !== state.newPassword.value,
        error: "Your passwords didn't match.",
      },
    },
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const onSubmit = () => {
    const data = {
      new_password1: state.newPassword.value,
      new_password2: state.confirmPassword.value,
      uid,
      token
    }
    props.resetRequest(data)
  }

  const [viewNewPassword, setViewNewPassword] = useState(false)
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false)

  return (
    <div>
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="6" md="8" sm="">
              <Form action="" className="form" method="">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 50
                  }}
                >
                  <img
                    alt=""
                    style={{
                      height: "64px",
                      width: "64px",
                      borderRadius: 0,
                      marginRight: "13px",
                      marginTop: -3
                    }}
                    src={Images.cardLogo}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Card className="card-login w-75 p-0" style={{ padding: 0 }}>
                    <CardBody
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 23
                      }}
                    >
                      <FormGroup>
                        <p
                          style={{
                            color: "#000000",
                            fontFamily: "Open Sans",
                            fontWeight: "600",
                            fontSize: 20,
                            textAlign: "center",
                            marginTop: 0
                          }}
                        >
                          Reset Password
                        </p>
                      </FormGroup>
                      <Form action="" className="form" method="">
                        <InputGroup
                          className="no-border"
                          style={{
                            border: "1.5px solid #808080",
                            borderRadius: "6px"
                          }}
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{ backgroundColor: "white" }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "14px"
                                }}
                                email_logo="true"
                                src={Images.lock}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="New password"
                            type={viewNewPassword ? "text" : "password"}
                            style={{
                              backgroundColor: "white",
                              height: "52px",
                              border: "3px solid white"
                            }}
                            onChange={e =>
                              handleOnChange("newPassword", e.target.value)
                            }
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{ backgroundColor: "white" }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "11px",
                                  width: "16px"
                                }}
                                email_logo="true"
                                src={Images.eye}
                                onClick={() => setViewNewPassword(!viewNewPassword)}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {state.newPassword.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              textAlign: "left",
                              marginTop: -6
                            }}
                          >
                            {state.newPassword.error}
                          </label>
                        )}
                        <InputGroup
                          className="no-border"
                          style={{
                            border: "1.5px solid #808080",
                            borderRadius: "6px"
                          }}
                        >
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{ backgroundColor: "white" }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "14px"
                                }}
                                email_logo="true"
                                src={Images.lock}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Confirm password"
                            type={viewConfirmPassword ? "text" : "password"}
                            style={{
                              backgroundColor: "white",
                              height: "52px",
                              border: "3px solid white"
                            }}
                            onChange={e =>
                              handleOnChange("confirmPassword", e.target.value)
                            }
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText
                              style={{ backgroundColor: "white" }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "11px",
                                  width: "16px"
                                }}
                                email_logo="true"
                                src={Images.eye}
                                onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {state.confirmPassword.error && (
                          <label
                            style={{
                              color: "red",
                              display: "flex",
                              textAlign: "left",
                              marginTop: -6
                            }}
                          >
                            {state.confirmPassword.error}
                          </label>
                        )}
                      </Form>
                    </CardBody>
                    <CardFooter style={{}}>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-evenly",
                          marginLeft: 10,
                          marginRight: 10
                        }}
                      >
                        <Button
                          className="mr-md-n2 text-capitalize"
                          style={{
                            backgroundColor: "#F01716",
                            height: 46,
                            fontFamily: "Submit",
                            fontWeight: "700",
                            fontSize: "16px",
                            borderRadius: "6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            width: 190,
                            marginBottom: 20
                          }}
                          onClick={onSubmit}
                          disabled={disable}
                        >
                          {props.requesting ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Reset Password"
                          )}
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/forgotBg.png")})`,
            alt: ""
          }}
        ></div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  requesting: state.ResetPassword.requesting
})

const mapDispatchToProps = dispatch => ({
  resetRequest: data => dispatch(resetRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)

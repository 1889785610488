import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { constants } from "./redux"
import { Card, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap"
import moment from "moment"
import "./style.scss"

import Images from "utils/Images"
import "react-datepicker/dist/react-datepicker.css"
import FilterComponent from "WebVisual/Components/FilterComponent"
import Pagination from "components/Pagination/Pagination"
import ClientCallsDetail from "../ClientCallsDetail"
import ActivityIndicator from "components/ActivityIndicator"
import { updateAnalyticsAction } from "../Settings/redux"

const PodcastList = ({ search }) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state.VisualPodcastReducer)
  const [selectedPodcast, setselectedPodcast] = useState(false)
  const [offset, setOffset] = useState(0)
  const [selectPageValue, setSelectPageValue] = useState(1)
  const getPodcasts = () => {
    dispatch({ type: constants.GET_PODCAST, offset })
  }

  useEffect(() => {
    getPodcasts()
  }, [offset])

  useEffect(() => {
    if (search) {
      dispatch({
        type: constants.SEARCH_PODCAST,
        search
      })
    } else {
      getPodcasts()
    }
  }, [search])

  const [isToggle, setIsToggle] = useState(false)
  const [isDateToggle, setIsDateToggle] = useState(false)
  const [date, setDate] = useState("")
  const [category, seCategory] = useState("")
  const onApplyFilter = (type, params) => {
    let dateQuery = date
    let categoryQuery = category
    if (type === "date") {
      setDate(params)
      dateQuery = params
    } else {
      seCategory(params)
      categoryQuery = params
    }
    dispatch({
      type: constants.DATE_FILETER,
      params: dateQuery + categoryQuery
    })
    setIsToggle(false)
    setIsDateToggle(false)
  }
  const onClickPodCast = (type, item, category) => {
    dispatch(updateAnalyticsAction(type, item, category))
  }

  return (
    <>
      {selectedPodcast ? (
        <ClientCallsDetail
          type='podCast'
          item={selectedPodcast}
          activeCallback={setselectedPodcast}
        />
      ) : (
        <div className="podcastWrapper">
          <FilterComponent
            onApplyFilter={onApplyFilter}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            isDateToggle={isDateToggle}
            setIsDateToggle={setIsDateToggle}
            listFound={`${state.count || state.podcasts.length || 0} Podcasts found`}
          />
          <Row className="article-wrapper">
            {state.requesting ? (
              <ActivityIndicator requesting />
            ) : !state.requesting && state.podcasts.length < 1 ? (
              <Row className="d-flex justify-content-center align-items-center m-auto">
                <p className="">No content found.</p>
              </Row>
            ) : (
              state.podcasts.map(item => (
                <Col md="6" xl="4" className="mb-5 d-flex flex-auto">
                  <Card style={{ flexDirection: "inherit", flex:1,display:"flex",justifyContent:"center"}}>
                    <Row className="w-100 justify-content-start">
                      <Col md="2" className="cardHeadWrapper p-0 ">
                        <div className="cardBox">
                          <CardHeader className="cardHeader">
                            <img src={Images.cardLogo} />
                          </CardHeader>
                        </div>
                      </Col>
                      <Col md="10" className="cardDetailWrapper px-3 px-md-0">
                        <CardTitle className="cardTitle">
                          <p className="podcast-card-title" onClick={() => {
                            onClickPodCast("podcast", item.id, "view")
                            setselectedPodcast(item)
                          }}>
                            {item.title}
                          </p>
                        </CardTitle>
                        <CardText>
                          <Row className="cardData">
                            <p className="categoryText">Category:</p>
                            <p className="categoryType">{item.category}</p>
                          </Row>
                          <p className="categoryDetail">{item.short_summary}</p>
                          <div className="d-flex justify-content-between dateWrraper">
                            <p>{moment(item.date).format("MMMM, DD, YYYY")}</p>
                            <img className="mediaIcons" src={item.media.includes("mp4") ? Images.videoIcon : Images.audioIcon} alt="" />
                          </div>
                        </CardText>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))
            )}
          </Row>
          {state.count > 10 &&
            <Row className="align-items-center justify-content-center">
            {state.requesting?"":  <Pagination
                totalCount={state && state.count}
                offset={offset}
                setOffset={setOffset}
                selectPageValue={selectPageValue}
                setSelectPageValue={setSelectPageValue}
              />}
            </Row>
          }
        </div>
      )}
    </>
  )
}

export default PodcastList

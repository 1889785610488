import React from "react"

import PageWrapper from 'WebVisual/PageWrapper'
import Notifications from "WebVisual/Components/Notifications"

const Notification = () => {
  return (
    <PageWrapper title="Notifications">
      <Notifications />
    </PageWrapper>
  )
}

export default Notification

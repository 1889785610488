import React, { useState } from "react"
import { NavItem, Nav, NavLink, Modal, Button, Row } from "reactstrap"
import Images from "utils/Images"
import { Logout } from "utils/functions"
import "../style.scss"

const Sidebar = ({ setPage }) => {
  const [modalDelete, setModalDelete] = useState(false)
  const [currentPage, setCurrentPage] = useState('Profile Information')

  const toggleDeleteModal = () => {
    setModalDelete(!modalDelete)
  }

  return (
    <>
      <div className="side-menu-setting">
        <Nav
          vertical
          className='list-unstyled pb-3'
        >
          <NavItem
            onClick={() => {
              setPage("Profile Information")
              setCurrentPage("Profile Information")
            }}
            className={currentPage == "Profile Information" ? "active" : ""}
            style={{ cursor: "pointer" }}
          >
            <NavLink to="/">Profile Information</NavLink>
          </NavItem>
          <NavItem
            onClick={() => {
              setPage("Change Password")
              setCurrentPage("Change Password")
            }}
            style={{ cursor: "pointer" }}
            className={currentPage == "Change Password" ? "active" : ""}
          >
            <NavLink to="/">Change Password</NavLink>
          </NavItem>
          <NavItem
            onClick={() => {
              setPage("Notifications")
              setCurrentPage("Notifications")
            }}
            style={{ cursor: "pointer" }}
            className={currentPage == "Notifications" ? "active" : ""}
          >
            {" "}
            <NavLink>Notifications</NavLink>
          </NavItem>
          <NavItem
            onClick={() => {
              setPage("Feedback")
              setCurrentPage("Feedback")
            }}
            style={{ cursor: "pointer" }}
            className={currentPage == "Feedback" ? "active" : ""}
          >
            {" "}
            <NavLink>Feedback</NavLink>
          </NavItem>
          <NavItem
            onClick={() => {
              setPage("Terms & Conditions")
              setCurrentPage("Terms & Conditions")
            }}
            style={{ cursor: "pointer" }}
            className={currentPage == "Terms & Conditions" ? "active" : ""}
          >
            <NavLink>Terms & Conditions</NavLink>
          </NavItem>
          <NavItem
            onClick={() => {
              setPage("Privacy Policy")
              setCurrentPage("Privacy Policy")
            }}
            className={currentPage == "Privacy Policy" ? "active" : ""}
            style={{ cursor: "pointer" }}
          >
            <NavLink>Privacy Policy</NavLink>
          </NavItem>
          <NavItem
            onClick={toggleDeleteModal}
            // onClick={() => setPage("Delete Account")}
            style={{ cursor: "pointer" }}
            className="delete-account"
          >
            <NavLink>Delete Account</NavLink>
          </NavItem>
          <NavItem
            onClick={() => Logout()}
            style={{ cursor: "pointer" }}
          >
            <NavLink>Logout</NavLink>
          </NavItem>
        </Nav>
      </div>
      <Modal
        isOpen={modalDelete}
        toggle={toggleDeleteModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            alt=""
            style={{
              height: 45,
              width: 38,
              marginTop: "40px"
            }}
            src={Images.deleteDark}
          />
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
            fontSize: "22px",
            color: "#4A5981"
          }}
        >
          Delete Account?
        </p>
        <p
          style={{
            marginTop: "-25px",
            padding: "35px",
            textAlign: "center",
            fontSize: "15px",
            color: "#000000"
          }}
        >
          Are you sure, you want to delete this account?
        </p>
        <Row style={{ justifyContent: "center", marginBottom: "25px" }}>
          <Button
            className="mr-lg-3"
            onClick={toggleDeleteModal}
            style={{
              backgroundColor: "#fff",
              color: "#F01716",
              border: "1px solid #F01716",
              borderRadius: "8px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#F01716",
              color: "#FFFF",
              border: "1px solid #F01716",
              borderRadius: "8px",
              width: "151px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
          >
            Delete
          </Button>
        </Row>
      </Modal>
    </>
  )
}
export default Sidebar
import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_END_PROCESS } from "./types"

export const forgotRequest = data => ({
  type: FORGOT_PASSWORD_REQUEST,
  data
})

export const forgotEndProcess = () => ({
  type: FORGOT_PASSWORD_END_PROCESS
})

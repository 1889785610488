import React from "react"
import { Button } from "reactstrap"

import Sidebar from "./SideBar"
import ProfileInformation from "./Profile"
// import ProfileInformation2 from "./Profile/profile2"
import ChangePassword from "./Change Password"
import Notifications from "./Notifications"
import FeedBack from "./Feedback"
import TermsandConditions from "./Terms & Cnditions"

import PrivacyPolicy from "./Privacy Policy"
import { ReactComponent as BackIcon } from "../../../assets/icons/sidebar/back-icon.svg"
import "./style.scss"
import { useState } from "react"
import { Link } from "react-router-dom"

const Settings = () => {
  const [currentPage, setCurrentPage] = useState("Profile Information")

  return (
    <div className="webvisuals-Settings">
      <div>
        <Link to="/user/home">
        <Button className="backBtn">
          <BackIcon />
        </Button>
        </Link>
      </div>
      <div className="seting-layout d-flex">
        <div className="sidebar-Setings">
          <Sidebar setPage={setCurrentPage} />
        </div>
        <div className="seting-content flex-grow-1">
          {currentPage === "Profile Information" && <ProfileInformation />}
          {currentPage === "Change Password" && <ChangePassword />}
          {currentPage === "Notifications" && <Notifications />}
          {currentPage === "Feedback" && <FeedBack />}
          {currentPage === "Terms & Conditions" && <TermsandConditions />}
          {currentPage === "Privacy Policy" && <PrivacyPolicy />}
        </div>
      </div>
    </div>
  )
}

export default Settings

import {
  GET_ARTICLES,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAILURE,
  PUBLISH_ARTICLES,
  PUBLISH_ARTICLES_SUCCESS,
  PUBLISH_ARTICLES_FAILURE,
  UPDATE_ARTICLES,
  UPDATE_ARTICLES_SUCCESS,
  UPDATE_ARTICLES_FAILURE,
  DELETE_ARTICLES,
  DELETE_ARTICLES_SUCCESS,
  DELETE_ARTICLES_FAILURE,
  FILTER_ARTICLES,
  FILTER_ARTICLES_SUCCESS,
  FILTER_ARTICLES_FALUIRE,
  SEARCH_ARTICLES,
  SEARCH_ARTICLES_SUCCESS,
  SEARCH_ARTICLES_FALUIRE
} from "./types"

const initialState = {
  requesting: false,
  articles: false,
  error: false,
  publishRequesting: false,
  updateRequesting: false,
  deleteRequesting: false,
  deletedArticle: false,
  publishedArticle: false,
  updatedArticle: false,
  searchRequesting: false,
  filterRequesting: false,
  searchedArticle: false,
  filteredArticle: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLES:
      return {
        ...state,
        requesting: true
      }

    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        requesting: false,
        articles: action.data
      }

    case GET_ARTICLES_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.error
      }

    case PUBLISH_ARTICLES:
      return {
        ...state,
        publishRequesting: true
      }

    case PUBLISH_ARTICLES_SUCCESS:
      return {
        ...state,
        publishRequesting: false,
        publishedArticle: action.data
      }

    case PUBLISH_ARTICLES_FAILURE:
      return {
        ...state,
        publishRequesting: false,
        error: action.error
      }

    case UPDATE_ARTICLES:
      return {
        ...state,
        updateRequesting: true
      }

    case UPDATE_ARTICLES_SUCCESS:
      return {
        ...state,
        updateRequesting: false,
        updatedArticle: action.data
      }

    case UPDATE_ARTICLES_FAILURE:
      return {
        ...state,
        updateRequesting: false,
        error: action.error
      }

    case DELETE_ARTICLES:
      return {
        ...state,
        deleteRequesting: true
      }

    case DELETE_ARTICLES_SUCCESS:
      return {
        ...state,
        deleteRequesting: false,
        deletedArticle: action.data
      }

    case DELETE_ARTICLES_FAILURE:
      return {
        ...state,
        deleteRequesting: false,
        error: action.error
      }

    case FILTER_ARTICLES:
      return {
        ...state,
        filterRequesting: true
      }

    case FILTER_ARTICLES_SUCCESS:
      return {
        ...state,
        filterRequesting: false,
        filteredArticle: action.data
      }

    case FILTER_ARTICLES_FALUIRE:
      return {
        ...state,
        filterRequesting: false,
        filteredArticle: false
      }

    case SEARCH_ARTICLES:
      return {
        ...state,
        searchRequesting: true
      }

    case SEARCH_ARTICLES_SUCCESS:
      return {
        ...state,
        searchRequesting: false,
        searchedArticle: action.data
      }

    case SEARCH_ARTICLES_FALUIRE:
      return {
        ...state,
        searchRequesting: false,
        searchedArticle: false
      }

    default:
      return state
  }
}


import React, { Component } from 'react'
// react plugin used to create switch buttons
import Switch from 'react-bootstrap-switch'

import { Button } from 'reactstrap'

class FixedPlugin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      classes: 'dropdown'
    }
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick() {
    if (this.state.classes === 'dropdown') {
      this.setState({ classes: 'dropdown' })
    } else {
      this.setState({ classes: 'dropdown' })
    }
  }
  render() {
    return (
      <div className='fixed-plugin'>
        <div className={this.state.classes}>
          {/* <div onClick={this.handleClick}>
            <i className='fa fa-cog fa-2x' />
          </div> */}
          <ul className='dropdown-menu'>
            <li className='header-title'>SIDEBAR BACKGROUND</li>
            <li className='adjustments-line'>
              <div className='badge-colors text-center'>
                <span
                  className={
                    this.props.bgColor === 'black'
                      ? 'badge filter badge-dark active'
                      : 'badge filter badge-dark'
                  }
                  data-color='black'
                  onClick={() => {
                    this.props.handleBgClick('black')
                  }}
                />
                <span
                  className={
                    this.props.bgColor === 'brown'
                      ? 'badge filter badge-default active'
                      : 'badge filter badge-default'
                  }
                  data-color='black'
                  onClick={() => {
                    this.props.handleBgClick('brown')
                  }}
                />
                <span
                  className={
                    this.props.bgColor === 'white'
                      ? 'badge filter badge-light active'
                      : 'badge filter badge-light'
                  }
                  data-color='white'
                  onClick={() => {
                    this.props.handleBgClick('white')
                  }}
                />
              </div>
            </li>
            <li className='header-title'>SIDEBAR ACTIVE COLOR</li>
            <li className='adjustments-line'>
              <div className='badge-colors text-center'>
                <span
                  className={
                    this.props.activeColor === 'primary'
                      ? 'badge filter badge-primary active'
                      : 'badge filter badge-primary'
                  }
                  data-color='primary'
                  onClick={() => {
                    this.props.handleActiveClick('primary')
                  }}
                />
                <span
                  className={
                    this.props.activeColor === 'info'
                      ? 'badge filter badge-info active'
                      : 'badge filter badge-info'
                  }
                  data-color='info'
                  onClick={() => {
                    this.props.handleActiveClick('info')
                  }}
                />
                <span
                  className={
                    this.props.activeColor === 'success'
                      ? 'badge filter badge-success active'
                      : 'badge filter badge-success'
                  }
                  data-color='success'
                  onClick={() => {
                    this.props.handleActiveClick('success')
                  }}
                />
                <span
                  className={
                    this.props.activeColor === 'warning'
                      ? 'badge filter badge-warning active'
                      : 'badge filter badge-warning'
                  }
                  data-color='warning'
                  onClick={() => {
                    this.props.handleActiveClick('warning')
                  }}
                />
                <span
                  className={
                    this.props.activeColor === 'danger'
                      ? 'badge filter badge-danger active'
                      : 'badge filter badge-danger'
                  }
                  data-color='danger'
                  onClick={() => {
                    this.props.handleActiveClick('danger')
                  }}
                />
              </div>
            </li>
            <li className='header-title'>SIDEBAR MINI</li>
            <li className='adjustments-line'>
              <div className='togglebutton switch-sidebar-mini'>
                <Switch
                  onChange={this.props.handleMiniClick}
                  value={this.props.sidebarMini}
                  onColor='info'
                  offColor='info'
                />
              </div>
            </li>
            <li className='button-container'>
              <Button
                href='https://www.creative-tim.com/product/PRC_Macro'
                color='primary'
                block
                className='btn-round'
                target='_blank'
              >
                Buy now
              </Button>
            </li>
            <li className='button-container'>
              <Button
                href='https://demos.creative-tim.com/PRC_Macro/#/documentation/tutorial'
                color='default'
                block
                className='btn-round'
                outline
                target='_blank'
              >
                <i className='nc-icon nc-paper' /> Documentation
              </Button>
            </li>
            <li className='button-container'>
              <Button
                href='https://www.creative-tim.com/product/paper-dashboard-react'
                color='info'
                block
                className='btn-round'
                target='_blank'
              >
                Get free version
              </Button>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default FixedPlugin

import React, { useEffect, useState } from "react"
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table
} from "reactstrap"
import ActivityIndicator from "components/ActivityIndicator"
import { BASE_URL } from "config/app"

//Libraries
import { Line } from "react-chartjs-2"
import { toast } from "react-hot-toast"

//Components
import Pagination from "components/Pagination/Pagination"
import { months, chartExample9 } from "../../variables/charts.js"
import { nonAdminErrorMessage } from "../../utils/functions"

import "./style.css"

const weekDays = 7
const monthDays = 30
const yearDays = 365

const dayFilterMapping = [
  { text: "Last 7 days", days: weekDays },
  { text: "Last 30 days", days: monthDays },
  { text: "Last 12 months", days: yearDays }
]

const Analytics = () => {
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [days, setDays] = useState(weekDays)
  const [refresh, setRefresh] = useState(false)
  const [analytics, setAnalytics] = useState([])
  const [requesting, setRequesting] = useState(false)
  const [requestingGraph, setRequestingGraph] = useState(false)
  const [analyticType, setAnalyticType] = useState("podcast")
  const [generatedGraphData, setGeneratedGraphData] = useState(chartExample9)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [applyBtn, setApplyBtn] = useState(false)
  const [selectPageValue, setSelectPageValue] = useState(1)

  const getDateFilter = () => {
    if (startDate && endDate) {
      const diffInMs = new Date(endDate) - new Date(startDate)
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24)
      setDays(diffInDays)
      setApplyBtn(true)
    }
  }

  const clearDateFilter = () => {
    setStartDate("")
    setEndDate("")
    setApplyBtn(false)
    setDays(weekDays)
  }

  // useEffect(() => {
  // setOffset(0);
  // fetchAnalyticsData(analyticType, 0);
  // console.log('11');
  // fetchGrapgData(days);
  // }, [])

  useEffect(() => {
    offset && setOffset(0)
    setTotal(0)
    setRefresh(!refresh)
    fetchAnalyticsData(analyticType, 0)
  }, [analyticType])

  useEffect(() => {
    fetchAnalyticsData(analyticType, offset)
  }, [offset])

  useEffect(() => {
    fetchGrapgData(days)
  }, [days])

  const getHeader = async () => {
    const accessToken = await sessionStorage.getItem("prc_authToken")
    return {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  }

  const fetchAnalyticsData = async (type, offsetValue) => {
    try {
      setRequesting(true)
      setTotal(0)
      fetch(
        `${BASE_URL}/api/v1/admin_content_analytics/?type=${type}&limit=10&offset=${offsetValue}`,
        await getHeader()
      )
        .then(response => response.json())
        .then(data => {
          if (data.count && data.results) {
            setTotal(data.count)
            setAnalytics(data.results)
          } else {
            setTotal(0)
            setAnalytics([])
            nonAdminErrorMessage(data.detail)
          }
        })
        .catch(() => {
          setTotal(0)
          setAnalytics([])
          toast.error(`Failed to get ${type.replace("_", " ")}.`)
        })
        .finally(() => setRequesting(false))
    } catch (error) { }
  }

  const fetchGrapgData = async selectedDays => {
    const labels = []
    const usersList = []
    setRequestingGraph(true)
    fetch(
      `${BASE_URL}/api/v1/user_activities/?days=${selectedDays}`,
      await getHeader()
    )
      .then(response => response.json())
      .then(data => {
        if (
          selectedDays === weekDays ||
          (selectedDays === monthDays && data?.date)
        ) {
          const sortDate = data?.date
          sortDate.sort((a, b) => new Date(a.date) - new Date(b.date))
          sortDate.forEach(({ date, users }) => {
            const dateObject = new Date(date)
            labels.push(
              `${months[dateObject.getMonth()]} ${dateObject.getDate()}`
            )
            usersList.push(users)
          })
        } else if (selectedDays === yearDays && data?.date) {
          const detailObject = {}
          data.date.forEach(({ date, users }) => {
            const dateObject = new Date(date)
            const currentMonth = dateObject.getMonth()
            if (detailObject[currentMonth]) {
              detailObject[currentMonth] += Number(users)
            } else {
              detailObject[currentMonth] = Number(users)
            }
          })
          for (let month in detailObject) {
            labels.push(months[month])
            usersList.push(detailObject[month])
          }
        }
        const config = {
          ...chartExample9,
          data: {
            labels,
            datasets: [
              {
                ...chartExample9.data.datasets[0],
                data: usersList
              }
            ]
          }
        }
        setGeneratedGraphData(config)
      })
      .catch(() => {
        setGeneratedGraphData(chartExample9)
        toast.error(`Failed to fetch graph data.`)
      })
      .finally(() => setRequestingGraph(false))
  }

  return (
    <div className="ml-lg-5 mr-lg-5 mr-3 ml-3">
      <Row>
        <Col md="12">
          <Card className="card-plain">
            <CardHeader>
              <Row
                className="d-md-flex m-0"
                style={{
                  justifyContent: "space-between"
                }}
              >
                <CardTitle
                  tag="h4"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#4A5981",
                    marginTop: "24px",
                    marginBottom: "30px"
                  }}
                >
                  Analytics
                </CardTitle>
              </Row>
            </CardHeader>
            <CardBody>
              <Card className="card">
                <CardHeader style={{}}>
                  <Row
                    className="px-3 "
                    style={{
                      alignItems: "center",
                      paddingBottom: "10px"
                    }}
                  >
                    <Col md="6">
                      <div>
                        <h3 style={{ fontWeight: "bold", margin: "0px" }}>
                          Audience Overview
                        </h3>
                      </div>
                    </Col>
                    {/* <Col md="6" className="mainDatesCol">
                      <div className="datesCard">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label style={{ alignSelf: "center", margin: "0px", fontWeight: 'bold' }}>
                            From:
                          </label>
                          <Input
                            type="date"
                            name="select"
                            value={startDate}
                            style={{
                              height: 41,
                              marginBottom: 33,
                              backgroundColor: "#fff",
                              margin: "0px",
                              fontWeight: "bold",
                              border: 0,
                              maxWidth: "160px"
                            }}
                            onChange={e => {
                              setStartDate(e.target.value)
                              setApplyBtn(false)
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center", fontWeight: 'bold' }}>
                          <label style={{ alignSelf: "center", margin: "0px" }}>
                            To:
                          </label>
                          <Input
                            type="date"
                            value={endDate}
                            name="select"
                            style={{
                              height: 41,
                              marginBottom: 33,
                              backgroundColor: "#fff",
                              margin: "0px",
                              fontWeight: "bold",
                              border: 0,
                              maxWidth: "160px"
                            }}
                            onChange={e => {
                              setEndDate(e.target.value)
                              setApplyBtn(false)
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        {applyBtn === false ? (
                          <Button onClick={getDateFilter}>Apply</Button>
                        ) : (
                          <Button
                            onClick={clearDateFilter}
                          >
                            Clear
                          </Button>
                        )}
                      </div>
                    </Col> */}
                  </Row>
                  {/* <Row
                    className="d-md-flex pl-2 py-1"
                    style={{
                      justifyContent: "space-between",
                      backgroundColor: "#f2f2f2",

                      alignItems: "center"
                    }}
                  >
                    <Col lg="5" sm="6" className=" mt-2" style={{}}>
                      <Row className="d-flex m-0 ">
                        <Col
                          lg="4"
                          sm="8"
                          className="p-0 d-md-flex "
                          style={{ justifyContent: "space-between" }}
                        >
                          <p
                            style={{
                              fontWeight: "700",
                              alignSelf: "center",
                              color: "#939393",
                              fontSize: 14,
                              marginRight: "20px"
                            }}
                          >
                            Email
                          </p>
                          <p
                            style={{
                              fontWeight: "700",
                              alignSelf: "center",
                              color: "#939393",
                              fontSize: 14,
                              marginRight: "20px"
                            }}
                          >
                            Export
                          </p>
                        </Col>
                        <Col
                          lg="7"
                          sm="8"
                          className="p-0 d-md-flex"
                          style={{ maxWidth: "fit-content" }}
                        >
                          <p
                            style={{
                              fontWeight: "700",
                              alignSelf: "center",
                              color: "#939393",
                              fontSize: 14,
                              whiteSpace: "nowrap",

                              marginRight: "20px"
                            }}
                          >
                            Add to Dashboard
                          </p>
                          <p
                            style={{
                              fontWeight: "700",
                              alignSelf: "center",
                              color: "#939393",
                              fontSize: 14,
                              marginRight: "20px"
                            }}
                          >
                            Shortcut
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6" className="p-0">
                      <Row className="d-flex m-0 p-0 " style={{}}>
                        <Col lg="6" className=" m-0 p-0 mt-2 ">
                          <p
                            style={{
                              margin: "0px",
                              alignSelf: "center",
                              color: "#939393"
                            }}
                          >
                            This report is based on 100% of sessions.{" "}
                            <span
                              style={{
                                color: "rgba(53, 162, 235)",
                                marginRight: "10px"
                              }}
                            >
                              Learn more
                            </span>
                          </p>
                        </Col>
                        <Col
                          lg="6 "
                          style={{ display: "flex", padding: "0px" }}
                        >
                          <Input
                            type="select"
                            name="select"
                            placeholder={"Greater percision"}
                            className="px-4"
                            id="exampleSelect"
                            style={{
                              height: 41,
                              maxWidth: "200px",
                              marginBottom: 33,
                              backgroundColor: "#F2F2F2",
                              margin: "5px 10px 0px 0px",

                              alignSelf: "center"
                            }}
                          >
                            <option>Greater percision</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                          <img
                            src={Images.hat}
                            alt=""
                            style={{
                              height: "30px",
                              width: "30px",
                              alignSelf: "center",
                              marginRight: "20px"
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}
                  {/* <Row className="d-md-flex m-0 p-0">
                    <Col lg="5" className="p-0">
                      <div
                        style={{
                          display: "flex",
                          border: "1px solid #E5E5E5",
                          margin: "5px 5px 0px 5px"
                        }}
                      >
                        <div style={{ alignSelf: "center", marginTop: "10px" }}>
                          <Doughnut
                            data={chartExample5.data}
                            options={chartExample5.options}
                            className="ct-chart ct-perfect-fourth"
                            height={50}
                            width={50}
                          />
                        </div>
                        <div>
                          <p
                            style={{
                              fontWeight: "bold",
                              padding: "10px 0px 0px 20px",
                              margin: "0px"
                            }}
                          >
                            All Users
                          </p>
                          <p
                            style={{
                              padding: "0px 0px 0px 20px",
                              margin: "0px"
                            }}
                          >
                            {"100.00% Sessions"}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg="5" className="p-0">
                      <div
                        style={{
                          display: "flex",
                          border: "2px dotted #E5E5E5",
                          margin: "5px 5px 0px 5px"
                        }}
                      >
                        <div style={{ alignSelf: "center", marginTop: "10px" }}>
                          <Doughnut
                            data={chartExample5.data}
                            options={chartExample5.options}
                            className="ct-chart ct-perfect-fourth"
                            height={50}
                            width={50}
                          />
                        </div>
                        <div>
                          <p
                            style={{
                              fontWeight: "bold",
                              margin: "0px",
                              padding: "20px 0px 0px 20px",
                              color: "#E5E5E5"
                            }}
                          >
                            + Add Segments
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                  <div
                    style={{
                      height: 50,
                      width: 100,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#f2f2f2",
                      display: "flex",
                      marginLeft: 30,
                      marginTop: 20,
                      borderTop: "2px solid #E5E5E5",
                      borderRight: "2px solid #E5E5E5",
                      borderLeft: "2px solid #E5E5E5"
                    }}
                  >
                    <p
                      className=""
                      style={{
                        fontWeight: "bold",
                        margin: 0
                      }}
                    >
                      Overview
                    </p>
                  </div>
                  <hr style={{ margin: 0 }} />
                </CardHeader>
                <CardBody>
                  <div>
                    <Row
                      className="d-md-flex m-0 p-0"
                      style={{
                        alignItems: "center"
                      }}
                    >
                      {/* <Col lg="6" className="p-0 ">
                        <Row
                          className="ml-lg-5 mt-3 m-0"
                          style={{
                            display: "flex",
                            padding: 0
                          }}
                        >
                          <Input
                            type="select"
                            name="select"
                            placeholder={"Greater percision"}
                            id="exampleSelect"
                            style={{
                              height: 41,
                              maxWidth: "130px",
                              marginBottom: 33,
                              backgroundColor: "#F2F2F2",
                              margin: "0px 10px 0px 0px",
                              alignSelf: "center"
                            }}
                          >
                            <option>Sessions</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                          <p
                            style={{
                              fontWeight: "bold",
                              margin: "0px",
                              alignSelf: "center",
                              marginRight: "15px"
                            }}
                          >
                            vs.
                          </p>
                          <p
                            style={{
                              margin: "0px",
                              alignSelf: "center",
                              color: "rgba(53, 162, 235)"
                            }}
                          >
                            Select a metric
                          </p>
                        </Row>
                      </Col> */}
                      <Col lg="12" className="p-0">
                        <div
                          className="d-md-flex m-0 p-0"
                          style={{ justifyContent: "flex-end" }}
                        >
                          {dayFilterMapping.map((item, i) => (
                            <p
                              key={i}
                              style={{
                                fontWeight: "bold",
                                alignSelf: "center",
                                border: "1px solid #E5E5E5",
                                padding: "10px",
                                margin: "30px 0px 0px 0px",
                                cursor: "pointer",
                                backgroundColor:
                                  item.days === days ? "#E5E5E5" : "#FFF"
                              }}
                              onClick={() => {
                                clearDateFilter()
                                setDays(item.days)
                              }}
                            >
                              {item.text}
                            </p>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <p
                    style={{
                      alignSelf: "center",
                      margin: "0px",
                      fontSize: "15px",
                      fontWeight: "bold"
                    }}
                  >
                    Sessions
                  </p>
                  <div style={{ height: "100%" }} className="display-md-none">
                    {requestingGraph ? (
                      <ActivityIndicator requesting />
                    ) : (
                      <Line
                        data={generatedGraphData.data}
                        options={generatedGraphData.options}
                        height={50}
                        maintainAspectRatio=" false"
                      />
                    )}
                  </div>

                  <Row className="d-md-flex m-0 p-0">
                    <Col md="12" className="p-0" style={{}}>
                      <Row md="12">
                        <Col md="4">
                          <div>
                            <p
                              style={{
                                color: "#0B0B0B",
                                fontSize: 18,
                                fontWeight: "700",
                                marginTop: 18,
                                marginLeft: 15
                              }}
                            >
                              Content Analytics
                            </p>
                          </div>
                        </Col>
                        <Col md="8" className="">
                          <div
                            className="d-md-flex m-0 p-0"
                            style={{ justifyContent: "flex-end" }}
                          >
                            {["podcast", "article", "client_calls"].map(
                              (text, i) => (
                                <p
                                  key={i}
                                  style={{
                                    fontWeight: "600",
                                    alignSelf: "center",
                                    border: "1px solid #E5E5E5",
                                    padding: "8px",
                                    margin: "30px 0px 0px 0px",
                                    color: "#6F6F6F",
                                    fontSize: "10px",
                                    paddingRight: "11px",
                                    paddingLeft: "11px",
                                    cursor: "pointer",
                                    textTransform: "capitalize",
                                    backgroundColor:
                                      analyticType === text ? "#E5E5E5" : "#FFF"
                                  }}
                                  onClick={() => setAnalyticType(text)}
                                >
                                  {text.replace("_", " ")}
                                </p>
                              )
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12" className="p-0">
                          <Table
                            responsive
                            style={{
                              marginLeft: "30px",
                              marginTop: "17px",
                              width: "95%"
                            }}
                          >
                            <thead>
                              <tr className="rowBorder">
                                <th style={{ textTransform: "none" }}>Title</th>
                                <th
                                  style={{ textTransform: "none" }}
                                  className="text-right"
                                >
                                  View
                                </th>
                                <th
                                  style={{ textTransform: "none" }}
                                  className="text-right"
                                >
                                  Downloaded
                                </th>
                                <th
                                  style={{ textTransform: "none" }}
                                  className="text-right"
                                >
                                  Shared
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {analytics.length ? (
                                analytics.map((item, i) => (
                                  <tr key={i} style={{ borderWidth: 0 }}>
                                    <td> {item?.title}</td>
                                    <td className="text-right">{item?.view}</td>

                                    <td className="text-right">
                                      {item?.downloaded}
                                    </td>
                                    <td className="text-right">
                                      {item?.shared}
                                    </td>
                                  </tr>
                                ))
                              ) : requesting ? (
                                <ActivityIndicator requesting />
                              ) : (
                                <tr style={{ borderWidth: 0 }}>
                                  <td colSpan={4}>
                                    <div className="text-left">
                                      No data available for given type.
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>

                          <Row
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 30
                            }}
                          >
                            {analytics.length ? (
                              <Pagination
                                refresh={refresh}
                                totalCount={total}
                                offset={offset}
                                setOffset={setOffset}
                                selectPageValue={selectPageValue}
                                setSelectPageValue={setSelectPageValue}
                              />
                            ) : (
                              <></>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col
                      lg="4"
                      md="4"
                      sm="12"
                      className=""
                      style={{ justifyContent: "center", marginRight: "-20px" }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          marginTop: "20px"
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span
                            style={{
                              height: "15px",
                              width: "15px",
                              backgroundColor: "rgb(53, 162, 235)",
                              display: "inline-block",
                              margin: "0px 15px 0px 20px"
                            }}
                          ></span>
                          <p style={{ fontSize: 12 }}>New Visitor</p>
                          <span
                            style={{
                              height: "15px",
                              width: "15px",
                              backgroundColor: "green",
                              display: "inline-block",
                              margin: "0px 15px 0px 20px",
                              whiteSpace: "nowrap"
                            }}
                          ></span>
                          <p style={{ fontSize: 12 }}>Returning Visitor</p>
                        </div>
                        <div style={{ alignItems: "center" }}>
                          <Pie
                            data={chartExample11.data}
                            options={chartExample11.options}
                            width={300}
                            height={170}
                          />
                        </div>
                      </div>
                    </Col> */}
                  </Row>

                  {/* <Row
                    className="d-md-flex m-0 p-0"
                    style={{
                      justifyContent: "space-between"
                    }}
                  >
                    <Col lg="3" md="3" sm="12" className="pr-0">
                      <Table responsive style={{ marginRight: "20px" }}>
                        <thead>
                          <tr>
                            <th>Demographics</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Dakota Rice</td>
                          </tr>
                          <tr>
                            <td>Minerva Hooper</td>
                          </tr>
                          <tr>
                            <td>Sage Rodriguez</td>
                          </tr>
                          <tr>
                            <td>Philip Chaney</td>
                          </tr>
                          <tr>
                            <td>Doris Greene</td>
                          </tr>
                          <tr>
                            <td>Mason Porter</td>
                          </tr>
                          <tr>
                            <td>Jon Porter</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col lg="9" md="9" sm="12" className="p-0">
                      <Table responsive style={{}}>
                        <thead>
                          <tr>
                            <th>Language</th>
                            <th className="text-right">Sessions</th>
                            <th>% Sessions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Niger</td>
                            <td className="text-right">Oud-Turnhout</td>
                            <td>
                              <span
                                style={{
                                  height: "15px",
                                  width: "65px",
                                  backgroundColor: "rgb(53, 162, 235)",
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px"
                                }}
                              ></span>
                              %71.45
                            </td>
                          </tr>
                          <tr>
                            <td>Curaçao</td>
                            <td className="text-right">Sinaai-Waas</td>
                            <td>
                              <span
                                style={{
                                  height: "15px",
                                  width: "65px",
                                  backgroundColor: "rgb(53, 162, 235)",
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px"
                                }}
                              ></span>
                              %71.45
                            </td>
                          </tr>
                          <tr>
                            <td>Netherlands</td>
                            <td className="text-right">Baileux</td>
                            <td>
                              <span
                                style={{
                                  height: "15px",
                                  width: "65px",
                                  backgroundColor: "rgb(53, 162, 235)",
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px"
                                }}
                              ></span>
                              %71.45
                            </td>
                          </tr>
                          <tr>
                            <td>Korea, South</td>
                            <td className="text-right">Overland Park</td>
                            <td>
                              <span
                                style={{
                                  height: "15px",
                                  width: "65px",
                                  backgroundColor: "rgb(53, 162, 235)",
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px"
                                }}
                              ></span>
                              %71.45
                            </td>
                          </tr>
                          <tr>
                            <td>Malawi</td>
                            <td className="text-right">
                              Feldkirchen in Kärnten
                            </td>
                            <td>
                              <span
                                style={{
                                  height: "15px",
                                  width: "65px",
                                  backgroundColor: "rgb(53, 162, 235)",
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px"
                                }}
                              ></span>
                              %71.45
                            </td>
                          </tr>
                          <tr>
                            <td>Chile</td>
                            <td className="text-right">Gloucester</td>
                            <td>
                              <span
                                style={{
                                  height: "15px",
                                  width: "65px",
                                  backgroundColor: "rgb(53, 162, 235)",
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px"
                                }}
                              ></span>
                              %71.45
                            </td>
                          </tr>
                          <tr>
                            <td>Portugal</td>
                            <td className="text-right">Gloucester</td>
                            <td>
                              <span
                                style={{
                                  height: "15px",
                                  width: "65px",
                                  backgroundColor: "rgb(53, 162, 235)",
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px"
                                }}
                              ></span>
                              %71.45
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row> */}
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Analytics

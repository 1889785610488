export default {
  filtericon: require("assets/icons/filter.png"),
  CSearch: require("assets/icons/search.png"),
  AudioFileIcon: require("assets/img/audio-icon.png"),

  img1: require("assets/icons/sidebar/img1.png"),
  img2: require("assets/icons/sidebar/img2.png"),
  img3: require("assets/icons/sidebar/img3.png"),
  img4: require("assets/icons/sidebar/img4.png"),
  img5: require("assets/icons/sidebar/img5.png"),
  img6: require("assets/icons/sidebar/img6.png"),
  img7: require("assets/icons/sidebar/img7.png"),
  img8: require("assets/icons/sidebar/img8.png"),

  reply: require("assets/img/reply.png"),
  time: require("assets/img/time.png"),
  rightp: require("assets/img/rightp.png"),
  right: require("assets/img/right.png"),
  leftp: require("assets/img/leftp.png"),
  left: require("assets/img/left.png"),

  filterDark: require("assets/img/filterDark.png"),
  filterD: require("assets/img/filterd.png"),
  cardLogo: require("assets/img/cardLogo.png"),
  pdfIcon: require("assets/img/pdfIcon.png"),
  delete: require("assets/img/delete.png"),
  edit: require("assets/img/edit.png"),
  cross: require("assets/img/cross.png"),
  line: require("assets/img/line.png"),
  deleteDark: require("assets/img/deleteDark.png"),
  editdark: require("assets/img/editdark.png"),

  hat: require("assets/img/hat.png"),
  horezantalLine: require("assets/img/horezantalLine.png"),
  del: require("assets/img/del.png"),
  emailLogo: require("assets/img/emailLogo.png"),
  tick: require("assets/img/tick.png"),
  goBack: require("assets/img/goBack.png"),
  lock: require("assets/img/lock.png"),
  eye: require("assets/img/eye.png"),
  calander: require("assets/img/cal.png"),
  filterb: require("assets/img/filterb.png"),
  clearb: require("assets/img/clearb.png"),
  clearLoader: require("assets/img/clearLoader.png"),
  logout: require("assets/img/logout.png"),
  image8: require("assets/img/image8.png"),
  emailImg: require("assets/img/emailImg.png"),
  settingIcon: require("assets/img/setting.png"),
  adminSettingIcon: require("assets/img/adminSetting.png"),
  bellIcon: require("assets/img/bell.png"),
  searchIcon: require("assets/img/searchIcon.png"),
  likeIcon: require("assets/img/like.png"),
  backIcon: require("assets/img/backIcon.png"),
  unLikeIcon: require("assets/img/unLikeIcon.png"),
  closeIcon: require("assets/img/closeIcon.png"),
  downloadIcon: require("assets/img/downloadIcon.png"),
  shareIcon: require("assets/img/shareIcon.png"),
  printIcon: require("assets/img/printIcon.png"),
  fbIcon: require("assets/img/fbIcon.png"),
  instaIcon: require("assets/img/instaIcon.png"),
  gmailIcon: require("assets/img/gmailIcon.png"),
  twiterIcon: require("assets/img/twiterIcon.png"),
  linkedInIcon: require("assets/img/linkedInIcon.png"),
  copyIcon: require("assets/img/copyIcon.png"),
  printerIcon: require("assets/img/printerIcon.png"),
  favIcon: require("assets/img/favicon.png"),
  videoIcon: require("assets/img/videoIcon.png"),
  audioIcon: require("assets/img/audioIcon.png"),
  dropdownIcon: require("assets/img/dropdownIcon.png"),
  pauseBtn: require("assets/img/pauseBtn.png"),
  playBtn: require("assets/img/playBtn.png"),
  resetBtn: require("assets/img/resetBtn.png"),
}

import React, { useState } from "react"

import PageWrapper from 'WebVisual/PageWrapper'
import FavouriteArticles from "WebVisual/Components/FavouriteArticles"

const Favourite = () => {
  const [search, setSearch] = useState('')

  return (
    <PageWrapper onChange={setSearch} title="Favourite">
      <FavouriteArticles search={search} />
    </PageWrapper>
  )
}

export default Favourite

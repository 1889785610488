import { all, call, put, takeLatest } from "redux-saga/effects"

import XHR from "../../../utils/XHR"

import { BASE_URL } from "config/app"
import { toast } from "react-hot-toast"

import {
  GET_ARTICLES,
  PUBLISH_ARTICLES,
  UPDATE_ARTICLES,
  DELETE_ARTICLES,
  FILTER_ARTICLES,
  SEARCH_ARTICLES
} from "./types"

import {
  getArticles,
  getArticlesSuccess,
  getArticlesFailure,
  publishArticlesSuccess,
  publishArticlesFailure,
  updateArticlesSuccess,
  updateArticlesFailure,
  deleteArticlesSuccess,
  deleteArticlesFailure,
  filterArticlesSuccess,
  filterArticlesFailure,
  searchArticlesSuccess,
  searchArticlesFailure
} from "./actions"

async function getArticlesAPI(data) {
  const URL = `${BASE_URL}/api/v1/article/?limit=10&offset=${data.offset}`
  const prc_authToken = await sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getArticlesFunction({ data }) {
  try {
    const response = yield call(getArticlesAPI, data)
    yield put(getArticlesSuccess(response.data))
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(getArticlesFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(getArticlesFailure(err))
    }
  }
}

async function publishArticlesAPI(data) {
  const URL = `${BASE_URL}/api/v1/admin_article/`
  const prc_authToken = await sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${prc_authToken}`
    },
    method: "POST",
    data: data.formdata
  }
  return XHR(URL, options)
}

function* publishArticlesFunction({ data, toggle }) {
  try {
    const response = yield call(publishArticlesAPI, data)
    yield put(publishArticlesSuccess(response.data))
    yield put(getArticles({ offset: 0 }))
    toast.success("Success !")
    toggle()
  } catch (e) {
    let err = e?.response?.data
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(publishArticlesFailure("Connection Error"))
    } else if (e?.response?.data?.logo) {
      toast.error("No logo file was submitted.")
      yield put(publishArticlesFailure("No logo file was submitted."))
    } else {
      yield put(publishArticlesFailure(err))
    }
  }
}

async function updateArticlesAPI(data, toggle) {
  const URL = `${BASE_URL}/api/v1/admin_article/${data.id}/`
  const prc_authToken = await sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${prc_authToken}`
    },
    method: "PATCH",
    data: data.formdata
  }
  return XHR(URL, options)
}

function* updateArticlesFunction({ data, toggle }) {
  try {
    const response = yield call(updateArticlesAPI, data)
    yield put(updateArticlesSuccess(response.data))
    yield put(getArticles({ offset: 0 }))
    toast.success("Updated !")
    toggle()
  } catch (e) {
    let err = e?.response?.data
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(updateArticlesFailure("Connection Error"))
    } else {
      yield put(updateArticlesFailure(err))
    }
  }
}

async function deleteArticlesAPI(data, toggle) {
  const URL = `${BASE_URL}/api/v1/admin_article/${data.id}/`
  const prc_authToken = await sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${prc_authToken}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* deleteArticlesFunction({ data, toggle }) {
  try {
    const response = yield call(deleteArticlesAPI, data)
    yield put(deleteArticlesSuccess(response))
    yield put(getArticles({ offset: 0 }))
    toast.success("Deleted !")
    toggle()
  } catch (e) {
    let err = e?.response?.data
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(deleteArticlesFailure("Connection Error"))
    } else {
      yield put(deleteArticlesFailure(err))
    }
  }
}

async function filterArticlesAPI(data) {
  const prc_authToken = await sessionStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/article/filters/?${data.category ? `category=["${data.category}"]&` : ''}to_date=${data.dateTo}&from_date=${data.dateFrom}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* filterArticlesData({ data, setIsToggle }) {
  try {
    const response = yield call(filterArticlesAPI, data)
    yield put(filterArticlesSuccess(response?.data))
    setIsToggle(false)
  } catch (e) {
    let err = e?.response?.data //?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(filterArticlesFailure("Connection Error"))
    } else {
      toast.error('Failed to get data, please try again')
      yield put(filterArticlesFailure('Failed to get data, please try again'))
    }
  }
}

async function searchArticlesAPI(data) {
  const prc_authToken = await sessionStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/article/filters/?name=${data.name}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* searchArticlesData({ data, setIsToggle }) {
  try {
    const response = yield call(searchArticlesAPI, data)
    yield put(searchArticlesSuccess(response?.data))
  } catch (e) {
    let err = e?.response?.data //?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(searchArticlesFailure("Connection Error"))
    } else {
      toast.error('Failed to get data, please try again')
      yield put(searchArticlesFailure('Failed to get data, please try again'))
    }
  }
}

export default all([
  takeLatest(GET_ARTICLES, getArticlesFunction),
  takeLatest(PUBLISH_ARTICLES, publishArticlesFunction),
  takeLatest(UPDATE_ARTICLES, updateArticlesFunction),
  takeLatest(DELETE_ARTICLES, deleteArticlesFunction),
  takeLatest(FILTER_ARTICLES, filterArticlesData),
  takeLatest(SEARCH_ARTICLES, searchArticlesData)
])

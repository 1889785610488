import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGING_FALUIRE, UPDATE_USER } from "./types"

const initialState = {
  user: false,
  requesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        user: action.data
      }

    case UPDATE_USER:
      return {
        ...state,
        user: action.data
      }

    case LOGING_FALUIRE:
      return {
        ...state,
        requesting: false
      }

    default:
      return state
  }
}

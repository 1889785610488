import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FALUIRE
} from "./types"

export const changePasswordRequest = data => ({
  type: CHANGE_PASSWORD_REQUEST,
  data
})

export const changePasswordSuccess = data => ({
  type: CHANGE_PASSWORD_SUCCESS,
  data
})

export const changePasswordFaluire = data => ({
  type: CHANGE_PASSWORD_FALUIRE,
  data
})

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_FALUIRE = "GET_ALL_USERS_FALUIRE"

export const CREATE_USERS_REQUEST = "CREATE_USERS_REQUEST"
export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS"
export const CREATE_USERS_FALUIRE = "CREATE_USERS_FALUIRE"

export const UPDATE_USERS_REQUEST = "UPDATE_USERS_REQUEST"
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS"
export const UPDATE_USERS_FALUIRE = "UPDATE_USERS_FALUIRE"

export const PAGINATION_HANDLING = "PAGINATION_HANDLING"

export const OFFSET_HANDLING = "OFFSET_HANDLING"

export const FILTER_USER = "FILTER_USER"
export const FILTER_USER_SUCCESS = "FILTER_USER_SUCCESS"
export const FILTER_USER_FALUIRE = "FILTER_USER_FALUIRE"

export const SEARCH_USER = "SEARCH_USER"
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS"
export const SEARCH_USER_FALUIRE = "SEARCH_USER_FALUIRE"

export const DELETE_USER = "Containers/UsersList/DELETE_USER"
export const DELETE_USER_SUCCESS = "Containers/UsersList/DELETE_USER_SUCCESS"
export const DELETE_USER_FAILURE = "Containers/UsersList/DELETE_USER_FAILURE"

export const SUSPEND_USER = "Containers/UsersList/SUSPEND_USER"
export const SUSPEND_USER_SUCCESS = "Containers/UsersList/SUSPEND_USER_SUCCESS"
export const SUSPEND_USER_FAILURE = "Containers/UsersList/SUSPEND_USER_FAILURE"

export const GET_PREFERRED_INTEREST = "GET_PREFERRED_INTEREST"
export const GET_PREFERRED_INTEREST_SUCCESS = "GET_PREFERRED_INTEREST_SUCCESS"
export const GET_PREFERRED_INTEREST_FAILURE = "GET_PREFERRED_INTEREST_FAILURE"

export const RESET_ALL_FILTER_DATA = "RESET_ALL_FILTER_DATA"

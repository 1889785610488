import { all, call, put, takeLatest } from "redux-saga/effects"

import XHR from "../../../utils/XHR"

import { BASE_URL } from "config/app"
import { toast } from "react-hot-toast"
import { Logout } from "utils/functions"
import { updateUser } from "../../../Containers/SignIn/redux/actions"

const TYPE = "WEBVISUAL_SETTINGS_"
export const constants = {
  POST_FEEDBACK: `${TYPE}POST_FEEDBACK`,
  RESET_REQUEST: `${TYPE}RESET_REQUEST`,
  UPDATE_NOTIFICATION_STATUS: `${TYPE}UPDATE_NOTIFICATION_STATUS`,
  DELETE_ACCOUNT: `${TYPE}DELETE_ACCOUNT`,
  UPDATE_USER_PROFILE: `${TYPE}UPDATE_USER_PROFILE`,
  CHANGE_PASSWORD: `${TYPE}CHANGE_PASSWORD`,
  CONTENT_ANALYTICS_UPDATE: `${TYPE}CONTENT_ANALYTICS_UPDATE`
}

export const updateAnalyticsAction = (contentType, id, category) => ({
  type: constants.CONTENT_ANALYTICS_UPDATE,
  contentType,
  id,
  category
})

const initialState = {
  requesting: false
}

export const VisualSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.POST_FEEDBACK:
    case constants.UPDATE_USER_PROFILE:
    case constants.CHANGE_PASSWORD:
      return {
        ...state,
        requesting: true
      }
    case constants.RESET_REQUEST:
      return {
        ...state,
        requesting: false
      }
    default:
      return state
  }
}

async function postFeedbackAPI(data) {
  const URL = `${BASE_URL}/api/v1/user_feedback/`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postFeedback({ data, setMessage }) {
  try {
    yield call(postFeedbackAPI, data)
    toast.success("Feedback submitted")
    // setEmail("")
    setMessage("")
  } catch (e) {
    toast.error("Failed to post feedback")
  } finally {
    yield put({ type: constants.RESET_REQUEST })
  }
}

async function updateNotificationStatusAPI(data) {
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const userId = sessionStorage.getItem("userId")
  const URL = `${BASE_URL}/api/v1/update_notification_status/${userId}/`

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* updateNotificationStatus({ user }) {
  try {
    const response = yield call(updateNotificationStatusAPI, {
      is_notification_on: !user.is_notification_enable
    })
    yield put(
      updateUser({
        ...user,
        is_notification_enable: !user.is_notification_enable
      })
    )
    toast.success(
      `Notifications have been turned ${response?.data?.is_notification_on ? "on" : "off"
      }`
    )
  } catch (e) {
    toast.error("Failed to update notification status")
  }
}

async function deleteAccountAPI() {
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/delete_account/`

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "PATCH"
  }
  return XHR(URL, options)
}

function* deleteAccount() {
  try {
    yield call(deleteAccountAPI)
    Logout()
  } catch (e) {
    toast.error("Failed to delete account.")
    yield put({ type: constants.RESET_REQUEST })
  }
}

async function updateUserProfileAPI(id, data) {
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/user_profile/${id}/`

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* updateUserProfile({ data, callback }) {
  try {
    yield call(updateUserProfileAPI, data.user_profile.id, data.user_profile)
    yield put(updateUser(data))
    callback()
  } catch (e) {
    toast.error("Failed to update user profile.")
  } finally {
    yield put({ type: constants.RESET_REQUEST })
  }
}

async function changePasswordAPI(data) {
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/change_password/`

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* changePassword({ data }) {
  try {
    yield call(changePasswordAPI, data)
    toast.success("Password updated successfully.")
  } catch (e) {
    toast.error("Please enter correct current password.")
  } finally {
    yield put({ type: constants.RESET_REQUEST })
  }
}

async function updateContentAnalyticsAPI(contentType, id, category) {
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/content_analytics/`

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "POST",
    data: {
      type: contentType, id, category
    }
  }
  return XHR(URL, options)
}

function* updateContentAnalytics({ contentType, id, category }) {
  try {
    yield call(updateContentAnalyticsAPI, contentType, id, category)
  } catch (e) {
    console.log('error', e);
  }
}

export default all([
  takeLatest(constants.POST_FEEDBACK, postFeedback),
  takeLatest(constants.UPDATE_NOTIFICATION_STATUS, updateNotificationStatus),
  takeLatest(constants.DELETE_ACCOUNT, deleteAccount),
  takeLatest(constants.UPDATE_USER_PROFILE, updateUserProfile),
  takeLatest(constants.CHANGE_PASSWORD, changePassword),
  takeLatest(constants.CONTENT_ANALYTICS_UPDATE, updateContentAnalytics),
])

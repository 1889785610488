import { all, call, put, takeLatest } from "redux-saga/effects"

import XHR from "../../../utils/XHR"

import { BASE_URL } from "config/app"
import { toast } from "react-hot-toast"
import { updateAnalyticsAction } from "../Settings/redux"

const TYPE = "WEBVISUAL_PODCAST_"
export const constants = {
  GET_PODCAST: `${TYPE}GET_PODCAST`,
  GET_PODCAST_SUCCESS: `${TYPE}GET_PODCAST_SUCCESS`,
  RESET_REQUEST: `${TYPE}RESET_REQUEST`,
  SEARCH_PODCAST: `${TYPE}SEARCH_PODCAST`,
  DATE_FILETER: `${TYPE}DATE_FILETER`,
  DOWNLOAD_PODCAST: `${TYPE}DOWNLOAD_PODCAST`,
  DOWNLOAD_PODCAST_SUCCESS: `${TYPE}DOWNLOAD_PODCAST_SUCCESS`,
}

const initialState = {
  requesting: false,
  downloadRequesting: false,
  podcasts: [],
  count: 0
}

export const VisualPodcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.DATE_FILETER:
    case constants.GET_PODCAST:
    case constants.SEARCH_PODCAST:
      return {
        ...state,
        requesting: true
      }
    case constants.GET_PODCAST_SUCCESS:
      return {
        ...state,
        podcasts: action.response,
        count: action.count,
        requesting: false
      }

    case constants.RESET_REQUEST:
      return {
        ...state,
        requesting: false
      }
    case constants.DOWNLOAD_PODCAST:
      return {
        ...state,
        downloadRequesting: true,
      }

    case constants.DOWNLOAD_PODCAST_SUCCESS:
      return {
        ...state,
        downloadRequesting: false,
      }

    default:
      return state
  }
}

async function getPodcastAPI(offset) {
  const URL = `${BASE_URL}/api/v1/podcast/?limit=10&offset=${offset}`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getPodcast({ offset }) {
  try {
    const response = yield call(getPodcastAPI, offset)
    yield put({
      type: constants.GET_PODCAST_SUCCESS,
      response: response.data.results,
      count: response.data.count
    })
  } catch (e) {
    yield put({ type: constants.RESET_REQUEST })
    toast.error("Failed to get podcasts")
  }
}

async function searchPodcastAPI(search) {
  const URL = `${BASE_URL}/api/v1/podcast/filters/?name=${search}`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* searchPodcasts({ search }) {
  try {
    const response = yield call(searchPodcastAPI, search)
    yield put({
      type: constants.GET_PODCAST_SUCCESS,
      response: response?.data,
      count: response?.data?.length
    })
  } catch (e) {
    toast.error("Failed to get podcasts")
  }
}

async function dateFilterAPI(params) {
  const URL = `${BASE_URL}/api/v1/podcast/filters/?${params}`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* dateFilter({ params }) {
  try {
    const response = yield call(dateFilterAPI, params)
    yield put({
      type: constants.GET_PODCAST_SUCCESS,
      response: response.data,
    })
  } catch (e) {
    yield put({ type: constants.RESET_REQUEST })
    toast.error("Failed to get data for selected date.")
  }
}

async function downloadPodcastAPI(id) {
  const URL = `${BASE_URL}/api/v1/podcast_download/${id}/download/?type=media`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET",
    responseType: "blob"
  }
  return XHR(URL, options)
}

function* downloadPodcast({ id, media }) {
  try {
    const response = yield call(downloadPodcastAPI, id)
    const fileURL = window.URL.createObjectURL(new Blob([response?.data]));
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = media.includes("mp4") ? `podcast_${id}.mp4` : `podcast_${id}.mp3`;
    alink.click();
    toast.success("Podcast downloaded sccessfully")
    yield put(updateAnalyticsAction('podcast', id, 'downloaded'))
    yield put({ type: constants.DOWNLOAD_PODCAST_SUCCESS })
  } catch (e) {
    toast.error("Failed to downloaded podcast")
  }
}
export default all([
  takeLatest(constants.GET_PODCAST, getPodcast),
  takeLatest(constants.SEARCH_PODCAST, searchPodcasts),
  takeLatest(constants.DATE_FILETER, dateFilter),
  takeLatest(constants.DOWNLOAD_PODCAST, downloadPodcast),

])

import React from "react"
import { Row, Spinner } from "reactstrap"
const ActivityIndicator = ({ requesting, data, message }) => {
  return data ? null : (
    <>
      {requesting ? (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "100%",
            margin: "20px"
          }}
        >
          <Spinner size="lg" />
        </div>
      ) : (
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            width: "100%",
            margin: "20px"
          }}
          className="p-0"
        >
          <p style={{ color: 'red', fontWeight: 'bold' }}>{message}</p>
        </Row>
      )}
    </>
  )
}

export default ActivityIndicator

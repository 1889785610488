import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGING_FALUIRE, UPDATE_USER } from "./types"

export const loginRequest = data => ({
  type: LOGIN_REQUEST,
  data
})

export const loginSuccess = data => ({
  type: LOGIN_SUCCESS,
  data
})

export const loginFaluire = () => ({
  type: LOGING_FALUIRE
})

export const updateUser = data => ({
  type: UPDATE_USER,
  data
})

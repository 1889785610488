import React, { useState } from "react"
import Images from "utils/Images"
import moment from "moment"
import "./style.css"

//Components
import {
  Button,
  Label,
  FormGroup,
  Input,
  DropdownMenu,
  DropdownItem,
  Spinner
} from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const constants = [
  { name: "Politics", value: "politics" },
  { name: "Commodities", value: "commodities" },
  { name: "Equities", value: "equities" },
  { name: "Rates", value: "rates" },
  { name: "FX", value: "fx" },
  { name: "Property", value: "property" },
  { name: "Presentations", value: "presentations" }
]

const DateFilterComponent = props => {
  const {
    requesting,
    categories,
    onApplyFilter
  } = props
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [selectedCategories, setSelectedCategories] = useState([])

  const generateParams = (array) => {
    const list = array.map(s => `"${s}"`).join(", ")
    return `category=${`[${list}]`}`
  }

  const clearFilter = () => {
    if (categories) {
      setSelectedCategories([])
      onApplyFilter && onApplyFilter('category', '')
    } else {
      setFromDate('')
      setToDate('')
      onApplyFilter && onApplyFilter('date', '')
    }
  }

  const applyFilter = () => {
    if (categories) {
      const category = selectedCategories.length ? generateParams(selectedCategories) : ''
      onApplyFilter && onApplyFilter('category', category)
    } else {
      const from = fromDate ? `from_date=${moment(fromDate).format("YYYY-MM-DD")}&` : ''
      const to = toDate ? `to_date=${moment(toDate).format("YYYY-MM-DD")}&` : ''
      onApplyFilter && onApplyFilter('date', from + to)
    }
  }

  const setCategory = value => {
    if (selectedCategories.includes(value)) {
      setSelectedCategories(selectedCategories.filter((item) => item !== value))
    } else {
      setSelectedCategories([...selectedCategories, value])
    }
  }

  return (
    <DropdownMenu
      persist
      aria-labelledby="navbarDropdownMenuLink"
      right
      className={`filterDropdown ${!categories?"selectDateToggle": "" } `}
    >
      {!categories &&
        <>
          <div
            style={{
              color: "black",
              fontSize: 15,
              fontWeight: "bold",
              padding: 15
            }}
          >
            Filter by date
          </div>
          <div
            style={{
              color: "black",
              fontSize: 12,
              fontWeight: "bold",
              padding: 15
            }}
          >
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center"
              }}
            >
              <div style={{ width: "48%" }}>
                <p style={{ fontSize: 15 }}>From</p>
                <div
                  style={{
                    backgroundColor: "#F2F2F2",
                    borderRadius: 6
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 15,
                      paddingRight: 16
                    }}
                  >
                    <img
                      src={Images.calander}
                      style={{
                        width: 16,
                        height: 15,
                        marginRight: 6,
                        borderRadius: 0
                      }}
                      alt=""
                    />
                    <DatePicker
                      placeholderText={"From date"}
                      className="form-Data dateInput"
                      selected={fromDate && fromDate}
                      onChange={date => setFromDate(date)}
                      dropdownMode="select"
                      dateFormat="d MMM, yyyy"
                      closeOnScroll={true}
                      locale="hu"
                      style={{fontSize:"16.5px",}}
                      disabledKeyboardNavigation
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: "48%" }}>
                <p style={{ fontSize: 15 }}>To</p>
                <div
                  style={{
                    backgroundColor: "#F2F2F2",
                    borderRadius: 6
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 15,
                      paddingRight: 16
                    }}
                  >
                    <img
                      src={Images.calander}
                      style={{
                        width: 16,
                        height: 15,
                        marginRight: 6,
                        borderRadius: 0
                      }}
                      alt=""
                    />
                    <DatePicker
                      className="form-Data dateInput"
                      placeholderText={"To date"}
                      selected={toDate && toDate}
                      onChange={date => setToDate(date)}
                      minDate={fromDate}
                      dropdownMode="select"
                      dateFormat="d MMM, yyyy"
                      closeOnScroll={true}
                      locale="hu"
                      disabledKeyboardNavigation
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      <DropdownItem divider />
      {categories &&
        <>
          <div
            style={{
              color: "black",
              fontSize: 15,
              fontWeight: "bold",
              padding: 15
            }}
          >
            Filter by Category
          </div>
          {constants.map((item, i) => {
            return (
              <div
                key={`${i}_${selectedCategories.length}`}
                style={{
                  fontSize: 15,
                  marginLeft: 16
                }}
              >
                <FormGroup check>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={selectedCategories.includes(item.value)}
                        onChange={() => setCategory(item.value)}
                      />
                      <span
                        className="form-check-sign"
                        style={{
                          height: "10px",
                          backgroundColor: "#3A0F7D"
                        }}
                      />
                      <span
                        style={{
                          color: "#000000",
                          fontWeight: "400",
                          fontSize: 15,
                          textTransform: "capitalize"
                        }}
                      >
                        {item.name}
                      </span>
                    </Label>
                  </div>
                </FormGroup>
              </div>
            )
          })}
        </>
      }
      <DropdownItem divider />
      <div
        style={{
          color: "#838181",
          fontFamily: "Khula",
          fontSize: 15,
          padding: 10
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Button
            style={{
              backgroundColor: "#FFF",
              height: 37,
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: "nowrap",
              color: "#F01716",
              border: "1px solid"
            }}
            className=" text-capitalize"
            onClick={applyFilter}
          >
            {requesting ? (
              <div
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  width: "100%"
                }}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              "Apply Filter"
            )}
          </Button>
          <Button
            style={{
              backgroundColor: "#F1F1F1",
              height: 37,
              borderRadius: "6px",
              marginLeft: 10,
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              color: "#4A5981"
            }}
            onClick={clearFilter}
            className=" text-capitalize"
          >
            <img
              style={{
                height: 16,
                width: 15,
                marginRight: 5
              }}
              alt=""
              src={Images.clearLoader}
            />
            Clear
          </Button>
        </div>
      </div>
    </DropdownMenu>
  )
}

export default DateFilterComponent

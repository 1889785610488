import { toast } from "react-hot-toast"

export const getState = state => {
  const keys = Object.keys(state)
  const values = Object.values(state)
  let data = {}
  keys.forEach((item, i) => {
    data = {
      ...data,
      [item]: values[i].value
    }
  })
  return data
}

export const characterEllipsis = (string, characters = 2) => {
  if (string.length > characters * 2) {
    return (
      string.substr(0, characters) +
      "***" +
      string.substr(string.length - characters, string.length)
    )
  }
  return string
}

export const nonAdminErrorMessage = message => {
  if (message === "Your are not a admin") {
    toast.error("No data available for non admin user")
  } else {
    toast.error("Request failed")
  }
}

export const Logout = async () => {
  await sessionStorage.clear()
  window.location.reload()
}

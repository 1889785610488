import React, { useRef, useState } from "react"
import {
  Button,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Spinner
} from "reactstrap"
import "./style.scss"

import moment from "moment"
import Images from "utils/Images"
import AudioPlayer from "react-h5-audio-player"
import { DefaultPlayer as Video, PROPERTIES } from "react-html5video"
import "react-datepicker/dist/react-datepicker.css"
import "react-h5-audio-player/lib/styles.css"
import "react-html5video/dist/styles.css"
import { useDispatch, useSelector } from "react-redux"
import { constants } from "../PodcastList/redux"
import { constants as clientConstants } from "../ClientCalls/redux"

const ClientCallsDetail = ({ item, activeCallback, type }) => {
  const [isToggle, setIsToggle] = useState(false)
  const [playerState, setPlayerState] = useState(false)
  const [isOpenPdf, setisOpenPdf] = useState(false)
  const dispatch = useDispatch()
  const state = useSelector(state => state.VisualClientCallsReducer)
  const statePodcast = useSelector(state => state.VisualPodcastReducer)

  const videRef = useRef(null)
  const playerRef = useRef(null)
  const componentRef = useRef()

  const pdfLink = item.document;
  const togglePlayer = () => {
    if (playerState) {
      playerRef.current.audio.current.pause()
      setPlayerState(false)
    } else {
      playerRef.current.audio.current.play()
      setPlayerState(true)
    }
  }
  const downlaodPodCast = () => {
    if (type === 'clientCalls') {
      dispatch({
        type: clientConstants.DOWNLOAD_CLIENT_CALLS,
        id: item.id,
        media: item.media
      })
    } else {
      dispatch({
        type: constants.DOWNLOAD_PODCAST,
        id: item.id,
        media: item.media
      })
    }
  }

  const resetPlayerSeek = () => {
    playerRef.current.audio.current.currentTime = 0
    playerRef.current.audio.current.pause()
    setPlayerState(false)
  }
  const renderAudioPlayer = () => (
    <div className="audio-video-screen">
      <Row className="justify-content-between topBtnWrraper">
        <div className="d-flex justify-content-between w-100">
          <Button className="backBtn" onClick={() => activeCallback(false)}>
            <img className="backIcon-img" src={Images.backIcon} alt="" />
          </Button>
          <Dropdown isOpen={isToggle} toggle={() => setIsToggle(!isToggle)} disabled={statePodcast.downloadRequesting}>
            <DropdownToggle
              aria-haspopup={true}
              color="default"
              data-toggle="dropdown"
              id="navbarDropdownMenuLink"
              nav
              className="dropdownTogle"
            >
              {statePodcast.downloadRequesting ?
                <Button
                  className="text-capitalize"
                  disabled
                >
                  <Spinner size="sm" />
                </Button>
                :
                <Button
                  onClick={() => {
                    setIsToggle(!isToggle)
                  }}
                  className=" text-capitalize"
                >
                  . . .
                </Button>
              }
            </DropdownToggle>
            <DropdownMenu
              persist
              aria-labelledby="navbarDropdownMenuLink"
              right
            >
              <DropdownItem
                className="first Item d-flex align-items-center"
                href="#"
              >
                <img
                  src={Images.downloadIcon}
                  alt=""
                  width="15px"
                  height="15px"
                />
                <span onClick={downlaodPodCast}>Download </span>
              </DropdownItem>
              <DropdownItem
                className="second Item d-flex align-items-center"
                href="#"
                onClick={() => activeCallback(false)}
              >
                <img src={Images.closeIcon} alt="" width="15px" height="15px" />
                <span>Close </span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Row>
      <Row className="content-box">
        <div className="header d-flex justify-content-center w-100">
          <img src={Images.cardLogo} width="40px" height="40px" />
        </div>
        <div className="content-boby w-100">
          <div className="player-info d-flex align-items-center flex-column text-center">
            <div className="audio-image d-flex justify-content-center align-items-center">
              <img src={Images.AudioFileIcon} alt="" />
            </div>
            <div className="discription">
              <p className="ttile">{item.title}</p>
              <p className="category">
                Category:<span> {item.category}</span>
              </p>
              <p className="date">
                {moment(item.date).format("MMMM, DD, YYYY")}
              </p>
            </div>
          </div>
          <div className="audio-player">
            <AudioPlayer
              showSkipControls={false}
              showJumpControls={false}
              loop={false}
              className="player-container"
              src={item.media}
              onPlay={e => console.log("onPlay")}
              ref={playerRef}
            />
          </div>
          <div className="player-controls d-flex justify-content-center align-items-center">
            <img
              height={60}
              width={60}
              src={playerState ? Images.pauseBtn : Images.playBtn}
              onClick={togglePlayer}
              style={{ cursor: "pointer" }}
            />
            <img
              height={38}
              width={38}
              src={Images.resetBtn}
              onClick={resetPlayerSeek}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="footer-info">
            <p>{item.short_summary}</p>
          </div>
        </div>
        {pdfLink !== null && isOpenPdf ?
          <Col md="12">
            <div
              style={{
                width: "100%",
                height: "850px",
                padding: 0,
                overflow: "hidden",
                position: "relative"
              }}
            >
              <iframe
                ref={componentRef}
                className="myframe"
                id="frame"
                src={`https://docs.google.com/viewerng/viewer?url=${pdfLink}&embedded=true`}
                // src={pdfLink+ "#toolbar=0" + "#view=fitH" }
                width="100%"
                height="100%"
                tricolor="white"
                frameBorder={"false"}
              />
            </div>
          </Col> : ""}

        {type === 'clientCalls' && pdfLink !== null && !isOpenPdf ?
          <div className="open-pdf-btn">
            <p onClick={() => setisOpenPdf(true)}>View pdf</p>
          </div> : ""}
      </Row>
    </div>
  )

  const renderVideoPlayer = () => (
    <div>
      <div className="audio-video-screen">
        <Row className="justify-content-between topBtnWrraper">
          <div className="d-flex justify-content-between w-100">
            <Button className="backBtn" onClick={() => activeCallback(false)}>
              <img className="backIcon-img" src={Images.backIcon} alt="" />
            </Button>
            <Dropdown isOpen={isToggle} toggle={() => setIsToggle(!isToggle)} disabled={state.downloadRequesting}>
              <DropdownToggle
                aria-haspopup={true}
                color="default"
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                nav
                className="dropdownTogle"
              >
                {state.downloadRequesting ?
                  <Button
                    className="text-capitalize"
                    disabled
                  >
                    <Spinner size="sm" />
                  </Button>
                  :
                  <Button
                    onClick={() => {
                      setIsToggle(!isToggle)
                    }}
                    className=" text-capitalize"
                  >
                    . . .
                  </Button>}
              </DropdownToggle>
              <DropdownMenu
                persist
                aria-labelledby="navbarDropdownMenuLink"
                right
              >
                <DropdownItem
                  className="first Item d-flex align-items-center"
                  href="#"
                >
                  <img
                    src={Images.downloadIcon}
                    alt=""
                    width="15px"
                    height="15px"
                  />
                  <span onClick={downlaodPodCast}>Download </span>
                </DropdownItem>
                <DropdownItem
                  className="second Item d-flex align-items-center"
                  href="#"
                  onClick={() => activeCallback(false)}
                >
                  <img
                    src={Images.closeIcon}
                    alt=""
                    width="15px"
                    height="15px"
                  />
                  <span>Close </span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Row>
        <Row className="content-box">
          <div className="header d-flex justify-content-center w-100">
            <img src={Images.cardLogo} width="40px" height="40px" />
          </div>
          <div className="content-boby video-screen w-100 p-0">
            <div className="video-player">
              <Video controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen', 'Subtitles']} ref={videRef}>
                <source
                  src={item.media}
                  type=""
                />
              </Video>
            </div>
            <div className="player-controls d-flex justify-content-center align-items-center"></div>
            <div className="player-info d-flex align-items-center flex-column text-center">
              <div className="discription">
                <p className="ttile">{item.title}</p>
                <p className="category">
                  Category:<span> {item.category}</span>
                </p>
                <p className="date">
                  {moment(item.date).format("MMMM, DD, YYYY")}
                </p>
              </div>
            </div>
            <div className="footer-info">
              <p>{item.short_summary}</p>
            </div>
          </div>

          {pdfLink !== null && isOpenPdf ?
            <Col md="12">
              <div
                style={{
                  width: "100%",
                  height: "850px",
                  padding: 0,
                  overflow: "hidden",
                  position: "relative"
                }}
              >
                <iframe
                  ref={componentRef}
                  className="myframe"
                  id="frame"
                  src={`https://docs.google.com/viewerng/viewer?url=${pdfLink}&embedded=true`}
                  // src={pdfLink+ "#toolbar=0" + "#view=fitH" }
                  width="100%"
                  height="100%"
                  tricolor="white"
                  frameBorder={"false"}
                />
              </div>
            </Col> : ""}

          {type === 'clientCalls' && pdfLink !== null && !isOpenPdf ?
            <div className="open-pdf-btn">
              <p onClick={() => setisOpenPdf(true)}>View pdf</p>
            </div> : ""}
        </Row>
      </div>
    </div>
  )

  return (
    <>
      {item.media.includes("mp4") ? renderVideoPlayer() : renderAudioPlayer()}
    </>
  )
}

export default ClientCallsDetail

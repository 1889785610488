import { initializeApp } from "firebase/app"
import { getToken, getMessaging, onMessage } from "firebase/messaging"

const firebaseConfig = {
  apiKey: "AIzaSyBYYA8KTqfUicEqxm5haYZyfXAOOfiBC-Q",
  authDomain: "prc-macro-366618.firebaseapp.com",
  projectId: "prc-macro-366618",
  storageBucket: "prc-macro-366618.appspot.com",
  messagingSenderId: "772653108803",
  appId: "1:772653108803:web:fc8c8f5ded7065ad0ccef6",
  measurementId: "G-J8CQS51GQ4"
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const onForegroundMessage = () =>
new Promise(resolve => onMessage(messaging, payload => resolve(payload)))

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then(serviceWorker => {
        if (serviceWorker) return serviceWorker
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope"
          }
        )
      })
  }
  throw new Error("The browser doesn`t support service worker.")
}

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then(serviceWorkerRegistration =>
    getToken(messaging, {
      vapidKey:
        "BDTAnoq8CI6kwogywrGJi7Zm-FI2ma1X76K5nLGp85L6GA12ExCO1awwcSmiq2YqSkHn5N_8HQZ6vdYkzMst7Jc",
      serviceWorkerRegistration
    })
  )
